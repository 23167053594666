.result-index{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .tn {
        display:block;
        margin-bottom:0.5rem;
        flex-basis: 19%;
        width: 19%;
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.4) ;
        border-radius: $pl-radius;
        background-color: #FFF;
        color: #444;
        transition: background-color 200ms ease-out, box-shadow 200ms ease-out, transform 200ms ease-out;
        &:hover{
            box-shadow: 0 0 16px 0 rgba(0,0,0,0.4);
            transform: scale(1.2);
            z-index:10;
        }
        .tn-picture {
            display:block;
            width: 100%;
            height:0;
            padding-top: 65%;
            overflow: hidden;
            border-radius: $pl-radius $pl-radius 0 0;
            background-repeat: no-repeat;
            background-color: rgba(0,0,0,0.2);
            background-position: 50% 50%;
            background-size: cover;
        }
        .tn-picture + .tn-content {
            border-radius: 0 0 $pl-radius $pl-radius;
        }
        .tn-content {
            border-radius: $pl-radius;
            padding:0.75rem;
            h3 {
                font-size: 1rem;
                font-weight: 500;
            }
        }
    }
}

.result-index-list{
    .tn {
        color: #444;
        &:hover{

        }
        .tn-content {
            h3 {
                font-size: 1rem;
                font-weight: 500;
            }
        }
    }
}

.result-categories {
    margin-bottom: 1rem;
    background-color: #F4F4F4;
    padding: 1rem;
}
