@use "sass:math";
/*--------------------------------  Card  -----------------------------------------  */

.card{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: .25rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
    margin-bottom: 1rem;
    .card-outline.bg-default   {color: inherit; background-color:#FFF !important; border-top: 3px solid $color_default;}
    .card-outline.bg-info      {color: inherit; background-color:#FFF !important; border-top: 3px solid $color_info;}
    .card-outline.bg-primary   {color: inherit; background-color:#FFF !important; border-top: 3px solid $color_primary;}
    .card-outline.bg-warning   {color: inherit; background-color:#FFF !important; border-top: 3px solid $color_warning;}
    .card-outline.bg-danger    {color: inherit; background-color:#FFF !important; border-top: 3px solid $color_danger;}
    .card-outline.bg-success   {color: inherit; background-color:#FFF !important; border-top: 3px solid $color_success;}
    .card-outline.card-default   {color: inherit; background-color:#FFF !important; border-top: 3px solid $color_default;}

    .card-outline.card-info      {color: inherit; border-top: 3px solid $color_info;}
    .card-outline.card-primary   {color: inherit; border-top: 3px solid $color_primary;}
    .card-outline.card-warning   {color: inherit; border-top: 3px solid $color_warning;}
    .card-outline.card-danger    {color: inherit; border-top: 3px solid $color_danger;}
    .card-outline.card-success   {color: inherit; border-top: 3px solid $color_success;}

    .card-header{
        background-color: transparent;
        border-bottom: 1px solid rgba(0,0,0,.125);
        padding: .75rem 1.25rem;
        position: relative;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;

    }
    .card-filter:first-child{
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
    }
    .card-filter{
        padding: 1rem;
        background-color: rgba(0,0,0,.03);
        border-bottom: 1px solid rgba(0,0,0,.125);
            .input-group:last-child{margin: 0;}
    }
    .card-body{
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.25rem;
        color: inherit;
    }
    .card-zone{
        flex: 1 1 auto;
        min-height: 1px;
        padding: 0 7.5px;
        color: inherit;
    }
    .card-footer{
        padding: .75rem 1.25rem;
        background-color: rgba(0,0,0,.03);
        border-top: 1px solid rgba(0,0,0,.04);
        font-size:0.9rem;
        color:#666;
    }
    .card-nav{
        padding: 0;
        background-color: rgba(0,0,0,.03);
        border-bottom: 1px solid rgba(0,0,0,.125);
    }
    .card-title{
        float: left;
        font-size: 1.1rem;
        font-weight: 600;
        color: inherit;
        margin: 0;
    }
    .upper-title{
        margin:0;
        font-family: $font_title;
        font-weight: 600;
    }

    &.card-user{
        .card-header {
            h3{margin: 0;}
            padding: 1rem;
            text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
            text-align:center;
            min-height:135px;
            padding-bottom:50px;
            margin-bottom:45px;
        }
        .user-image {
            left: 50%;
            margin-left: -45px;
            position: absolute;
            bottom: -45px;
            img {
                width:90px;
                overflow:hidden;
                border-radius:50%;
                border:solid 2px #FFF;
            }
        }
        .user-symbol {
            left: 50%;
            margin-left: -30px;
            position: absolute;
            top: 105px;
            .symbol {
                display:flex;
                width:60px;
                height:60px;
                overflow:hidden;
                border-radius:50%;
                border:solid 2px #FFF;
                background-color: #CCC;
                color:#FFF;
                align-items: center;
                justify-content: center;
                font-size:1.6rem;
            }
        }
        .user-image + .card-body{
            padding-top: 60px;
        }
         .user-symbol + .card-body {
            /*padding-top: 40px;*/
        }
        .card-user-info {
            &:after {
                display: block;
                content : '\273D\273D\273D';
                color: $color_info;
                margin:0.5rem 0;
            }
        }
        .card-user-list {
            padding:0;
            margin:0;
            .card-user-list-item{
                border-top: solid 1px rgba(0,0,0,0.2);
                padding: 5px 0 ;
                display: flex;
                justify-content: space-between;
                list-style: none;
                &:first-child{border: none;}
            }
        }
    }
    &.card-fusion {
        margin-bottom: 0;
        border-radius : 0.25rem 0.25rem 0 0;
        border-bottom:0;
    }
    &.card-fusion + .card {
        border-top: none;
        border-top: dashed 1px rgba(0,0,0,.125);;
        border-radius : 0 0 0.25rem 0.25rem;
    }
    &.card-light {
        background-color: transparent;
        border: none;
        box-shadow: none;
        .card-header {border: 0;}
        .card-body {
            border: 1px solid rgba(0,0,0,.125);
            border-radius : 0 6px 6px 6px;
            background-color: #FFF;
        }
        .nav-tabs { padding-left : 0;}
    }
}

.card-body-color-article {
    background-color: #eef1f3;
    h6 {color: #135e93;}
}

$color-zone : #6d8f90;
$color-zone-blue : #0e84b5;
$color-zone-purple : #9c0eb5;
$color-zone-yellow : #b5860e;
.card-zone{
    margin-left: math.div($gutter-width, 2);
    margin-right: math.div($gutter-width, 2);;
}
.zone {
    border-radius: 0.25rem 0 0 0.25rem;
    padding: $gutter-width !important;
    background-color: rgba($color-zone, 0.1);
    border-right: dashed 1px;
    border-color: rgba($color-zone, 0.3);
    .zone-title{ color: $color-zone;}
    &:last-child {
        border-right: none;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }
    &.zone-yellow {
        background-color: rgba($color-zone-yellow, 0.1);
        border-color: rgba($color-zone-yellow, 0.2);
        .zone-title{ color: $color-zone-yellow;}
    }
    &.zone-blue {
        background-color: rgba($color-zone-blue, 0.1);
        border-color: rgba($color-zone-blue, 0.2);
        .zone-title{ color: $color-zone-blue;}
    }
    &.zone-purple {
        background-color: rgba($color-zone-purple, 0.1);
        border-color: rgba($color-zone-purple, 0.2);
        .zone-title{ color: $color-zone-purple;}
    }
}
.zone + .zone {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.zone-title {
    font-size: 1.1rem;
    font-weight: 500;
}

/*--------------------------------  nav tab  -----------------------------------------  */

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-tabs {
    position:relative;
    top:1px;
    padding-top:0.3rem;
    padding-left: 0.3rem;
    font-family: $font_title;
    font-weight:500;
    .nav-item {}
    .nav-link{
        display: block;
        padding: .5rem 1rem;
        border: 1px solid transparent;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
        color: $color_link;
        &.active{
            color: #495057;
            background-color: #fff;
            border-color: #dee2e6 #dee2e6 #fff;
        }
    }
    &.nav-tabs-bechamel {
        height: 36px;
        padding-top:0.1rem;
        background: linear-gradient(#DAE2E5, #b8c8cd);
        .nav-link{
            font-size: .9rem;
            padding: 6px 1rem 1px 1rem;
            a {
                color: #000;
                font-weight: 600;
                font-family: 'Abel';
            }
        }
    }
}
.tabs-content{
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: .25rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
    padding: 1.25rem;
    &.tabs-content-bechamel {
        box-shadow: none;
        .tab-content {
            border: none;
        }
    }
}
.tab-content>.tab-pane {
    display: none;
    &.active{
        display: block;
    }
}

.card-header.bg-primary:not(.card-outline)> .nav-tabs a.nav-link {
    color: #fff;
    &.active{color: #495057;}
}
.card-nav.bg-primary:not(.card-outline)> .nav-tabs a.nav-link {
    color: #fff;
    &.active{color: #495057;}
}

.tab-block {
    .nav-tabs {
        padding: 0;
    }
    .tab-content {
        background-color: #FFF;
        padding: 0.75rem;
        border: 1px solid #dee2e6;
        border-radius: 0 .25rem .25rem .25rem;
    }
}
