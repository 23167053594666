@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Dosis:wght@200;300;400;500;600;700;800&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Couleurs                                                                          */
/* ///////////////////////////////////////////////////////////////////////////////////////*/

$color_body: #fff;
$color_column: #323b3b;

$color_logo_bkg: #FFF;
$color_logo: rgba(255,255,255,0.5);
$color_logo_2: #00e7ff;

$color_navbar: rgba(52, 61, 61, 0.13);
$color_navbar2: rgba(52, 61, 61, 0.08);


$color_toolbox: #00e7ff;
$color_toolbox_dark: #009fac;

$color_default : #c4cece;
$color_primary : #0062bf;
$color_success : #56ad00;
$color_info : #00a1ad;
$color_warning : #fba900;
$color_danger : #d8002c;

$color_link: #0a5f84;
$color_text: #111;

$color_pink : #fb00e2;
$color_purple : #6200ff;
$color_horizon : #51697b;
$color_sable : #76501c;



/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Dimensions                                                                        */
/* ///////////////////////////////////////////////////////////////////////////////////////*/


$header-height: 220px;
$aside-width: 250px;

$pl-radius : 0;



$font_text : 'Source Sans Pro', 'Calibri', 'Arial';
$font_title : 'Dosis', 'Calibri', 'Arial';
$font_interface : 'Bebas Neue', 'Arial';

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);

