

.chart-bar{
    position:relative;
    display:flex;
    border-radius:3px;
    padding: 2px;
    overflow: hidden;
    .chart-bar-element:first-child{ border-radius: 3px 0 0 3px;}
    .chart-bar-element:last-child{ border-radius: 0 3px 3px 0;}
    .chart-bar-element{
        position:relative;
        height:20px;


    }
}


.progress{
    display: block;
    height: 20px;
    width: 100%;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
    &.progress-xs{height : 8px}
    &.progress-sm{height : 16px}
    &.progress-md{height : 20px}
    &.progress-xl{height : 26px}
    .progress-bar{
        display:block;
        width:10%;
        height:100%;
        background-color: #AAA;
    }
    .progress-bar-green {background-color: green;}
    .progress-bar-yellow {background-color: yellow;}
    .progress-bar-red {background-color: red;}
    .progress-bar-blue {background-color: blue;}
}

.canvas-chart{
    bottom:0;
}

