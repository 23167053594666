@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Dosis:wght@200;300;400;500;600;700;800&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
}

@-ms-viewport {
  width: device-width;
}
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  min-height: 100%;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: theme-color("primary");
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role=button],
input:not([type=range]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Couleurs                                                                          */
/* ///////////////////////////////////////////////////////////////////////////////////////*/
/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Dimensions                                                                        */
/* ///////////////////////////////////////////////////////////////////////////////////////*/
.table {
  width: 100%;
  font-size: 0.9rem;
}
.table.table-sm {
  font-size: 0.8rem;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table td, .table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table.table-striped tr:nth-child(odd) td {
  background-color: rgba(0, 0, 0, 0.02);
}
.table.no-border,
.table.no-border td,
.table.no-border th {
  border: 0;
}
.table.text-center,
.table.text-center td,
.table.text-center th {
  text-align: center;
}
.table.table-valign-middle thead > tr > th,
.table.table-valign-middle thead > tr > td,
.table.table-valign-middle tbody > tr > th,
.table.table-valign-middle tbody > tr > td {
  vertical-align: middle;
}
.card-body.p-0 .table thead > tr > th:first-of-type,
.card-body.p-0 .table thead > tr > td:first-of-type,
.card-body.p-0 .table tbody > tr > th:first-of-type,
.card-body.p-0 .table tbody > tr > td:first-of-type {
  padding-left: 1.25rem;
}
.card-body.p-0 .table thead > tr > th:last-of-type,
.card-body.p-0 .table thead > tr > td:last-of-type,
.card-body.p-0 .table tbody > tr > th:last-of-type,
.card-body.p-0 .table tbody > tr > td:last-of-type {
  padding-right: 1.25rem;
}
.table.table-condensed {
  font-size: 0.7rem;
}
.table.table-condensed td, .table.table-condensed th {
  padding: 0.25rem;
}
.table.table-sortable th.sortable {
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
}
.table.table-sortable th.sortable:after {
  display: block;
  position: absolute;
  left: 0.75rem;
  top: 0.75rem;
  color: #0a5f84;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0dc";
  opacity: 0.5;
}
.table.table-sortable th.sortable.sortable-active:after {
  content: "\f160";
  opacity: 1;
}
.table.table-sortable th.sortable.sortable-active.sortable-desc:after {
  content: "\f884";
}
.table.table-border {
  border: solid 2px #CCC;
}
.table.table-tiny-border {
  border: solid 1px #CCC;
}
.table.table-shadow {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.13), 0 1px 3px rgba(0, 0, 0, 0.2);
}

.float-right {
  float: right;
}

a {
  color: #0a5f84;
}

.font-text {
  font-family: "Source Sans Pro", "Calibri", "Arial";
}

.font-title {
  font-family: "Dosis", "Calibri", "Arial";
}

.d-none {
  display: none !important;
}

.d-out {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.h-0 {
  position: absolute;
  top: -9999px;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.break-word {
  overflow-wrap: break-word !important;
}

.list-unstyled {
  padding: 0;
  margin: 0;
  list-style: none;
}

dl.key-value {
  display: flex;
  flex-wrap: wrap;
  font-family: "Dosis", "Calibri", "Arial";
  border-top: solid 2px rgba(0, 0, 0, 0.2);
  border-bottom: solid 2px rgba(0, 0, 0, 0.2);
}
dl.key-value dt, dl.key-value dd {
  padding: 0.2em 0 0.1em 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  flex: 0 0 50%;
}
dl.key-value dt {
  font-weight: 600;
}
dl.key-value dt:last-of-type {
  border-bottom-width: 0;
}
dl.key-value dd {
  font-weight: 500;
  text-align: right;
}
dl.key-value dd:last-child {
  border-bottom-width: 0;
}

.font-size-09 {
  font-size: 0.9em;
}

.clear {
  clear: both;
}

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*     Couleur et deco                                                                    */
/* ///////////////////////////////////////////////////////////////////////////////////////*/
.bg-default {
  background-color: #c4cece !important;
  color: #FFF;
}

.bg-primary {
  background-color: #0062bf !important;
  color: #FFF;
}

.bg-success {
  background-color: #56ad00 !important;
  color: #FFF;
}

.bg-info {
  background-color: #00a1ad !important;
  color: #FFF;
}

.bg-warning {
  background-color: #fba900 !important;
  color: #FFF;
}

.bg-danger {
  background-color: #d8002c !important;
  color: #FFF;
}

.bg-horizon {
  background-color: #51697b !important;
  color: #FFF;
}

.bg-sable {
  background-color: #76501c !important;
  color: #FFF;
}

.bg-horizon-light {
  background-color: #93a8b8 !important;
  color: #FFF;
}

.soft-gradient {
  background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2));
}

.stripped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
  background-size: 16px 16px;
}

.soft-gradient.stripped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent), linear-gradient(rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2));
  background-size: 16px 16px, cover;
}

.ecosse {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 2%, transparent 2%, transparent 20%, rgba(255, 255, 255, 0.1) 15%, rgba(255, 255, 255, 0.1) 35%, transparent 35%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 52%, transparent 52%, transparent 70%, rgba(255, 255, 255, 0.1) 65%, rgba(255, 255, 255, 0.1) 85%, transparent 85%), linear-gradient(-45deg, rgba(0, 0, 0, 0.1) 2%, transparent 2%, transparent 20%, rgba(255, 255, 255, 0.1) 15%, rgba(255, 255, 255, 0.1) 35%, transparent 35%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 52%, transparent 52%, transparent 70%, rgba(255, 255, 255, 0.1) 65%, rgba(255, 255, 255, 0.1) 85%, transparent 85%);
  background-size: 45px 45px, 45px 45px;
}

.soft-gradient.ecosse {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 2%, transparent 2%, transparent 20%, rgba(255, 255, 255, 0.1) 15%, rgba(255, 255, 255, 0.1) 35%, transparent 35%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 52%, transparent 52%, transparent 70%, rgba(255, 255, 255, 0.1) 65%, rgba(255, 255, 255, 0.1) 85%, transparent 85%), linear-gradient(-45deg, rgba(0, 0, 0, 0.1) 2%, transparent 2%, transparent 20%, rgba(255, 255, 255, 0.1) 15%, rgba(255, 255, 255, 0.1) 35%, transparent 35%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 52%, transparent 52%, transparent 70%, rgba(255, 255, 255, 0.1) 65%, rgba(255, 255, 255, 0.1) 85%, transparent 85%), linear-gradient(rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2));
  background-size: 45px 45px, 45px 45px, cover;
}

.shadow-light {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

.shadow-medium {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3), 0 3px 6px rgba(0, 0, 0, 0.5) !important;
}

.shadow-strong {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5), 0 3px 6px rgba(0, 0, 0, 0.8) !important;
}

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Styles de texte                                                                   */
/* ///////////////////////////////////////////////////////////////////////////////////////*/
h1, h2, h3, h4, h5, h6 {
  font-family: "Dosis", "Calibri", "Arial";
  font-weight: 300;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  color: inherit;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
  font-weight: 400;
}

h4 {
  font-size: 1.5rem;
  font-weight: 400;
}

h5 {
  font-size: 1.25rem;
  font-weight: 500;
}

h6 {
  font-size: 1rem;
  font-weight: 500;
}

blockquote {
  position: relative;
  padding: 0 1.6rem;
  color: rgba(17, 17, 17, 0.8);
}
blockquote:before {
  display: block;
  position: absolute;
  content: "\f10d";
  font-size: 1rem;
  color: #AAA;
  font-weight: 900;
  top: 0;
  left: 0;
  font-family: "Font Awesome 5 Free";
}
blockquote:after {
  display: block;
  position: absolute;
  content: "\f10e";
  font-size: 1rem;
  color: #AAA;
  font-weight: 900;
  bottom: 0;
  right: 0;
  font-family: "Font Awesome 5 Free";
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

/* ------------------------------------------------------------*/
a.discret-link {
  color: inherit;
}
a.discret-link:hover {
  color: #0a5f84;
}

/* ------------------------------------------------------------*/
.text-default {
  color: #c4cece !important;
}

.text-primary {
  color: #0062bf !important;
}

.text-success {
  color: #56ad00 !important;
}

.text-info {
  color: #00a1ad !important;
}

.text-warning {
  color: #fba900 !important;
}

.text-danger, .text-red {
  color: #d8002c !important;
}

.text-toolbox {
  color: #00e7ff !important;
}

.text-link {
  color: #0a5f84 !important;
}

.text-pink {
  color: #fb00e2 !important;
}

.text-purple {
  color: #6200ff !important;
}

.text-horizon {
  color: #51697b !important;
}

.text-sable {
  color: #76501c !important;
}

.text-light {
  color: #FFF !important;
}

.text-dark {
  color: #000 !important;
}

hr {
  height: 1px;
  margin: 1rem 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
  border: 0;
}

.text-md {
  font-size: 0.9rem;
}

.text-sm {
  font-size: 0.8rem;
}

.text-xs {
  font-size: 0.7rem;
}

.text-200 {
  font-weight: 200;
}

.text-300 {
  font-weight: 300;
}

.text-400 {
  font-weight: 400;
}

.text-500 {
  font-weight: 500;
}

.text-600 {
  font-weight: 600;
}

.text-700 {
  font-weight: 700;
}

.text-800 {
  font-weight: 800;
}

.text-900 {
  font-weight: 900;
}

.text-tin {
  font-weight: 200;
}

.text-light {
  font-weight: 300;
}

.text-regular {
  font-weight: 500;
}

.text-bold {
  font-weight: 700;
}

.text-black {
  font-weight: 900;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-tonic {
  color: #00e7ff !important;
}

/*--------------------------------  Badge  -----------------------------------------  */
.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.22rem 0.2rem 0.14rem 0.2rem;
  height: auto;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge.badge-light {
  color: #FFF;
}
.badge.badge-outline {
  border: solid 1px #000;
}

.badge-default {
  background-color: #c4cece !important;
  color: #FFF;
}

.badge-primary {
  background-color: #0062bf !important;
  color: #FFF;
}

.badge-success {
  background-color: #56ad00 !important;
  color: #FFF;
}

.badge-info {
  background-color: #00a1ad !important;
  color: #FFF;
}

.badge-warning {
  background-color: #fba900 !important;
  color: #FFF;
}

.badge-danger {
  background-color: #d8002c !important;
  color: #FFF;
}

.badge-horizon {
  background-color: #51697b !important;
  color: #FFF;
}

.badge-sable {
  background-color: #76501c !important;
  color: #FFF;
}

.badge-outline.badge-default {
  background-color: #FFF !important;
  color: #c4cece !important;
  border-color: #c4cece !important;
}

.badge-outline.badge-primary {
  background-color: #FFF !important;
  color: #0062bf !important;
  border-color: #0062bf !important;
}

.badge-outline.badge-success {
  background-color: #FFF !important;
  color: #56ad00 !important;
  border-color: #56ad00 !important;
}

.badge-outline.badge-info {
  background-color: #FFF !important;
  color: #00a1ad !important;
  border-color: #00a1ad !important;
}

.badge-outline.badge-warning {
  background-color: #FFF !important;
  color: #fba900 !important;
  border-color: #fba900 !important;
}

.badge-outline.badge-danger {
  background-color: #FFF !important;
  color: #d8002c !important;
  border-color: #d8002c !important;
}

.badge-outline.badge-horizon {
  background-color: #FFF !important;
  color: #51697b !important;
  border-color: #51697b !important;
}

.badge-outline.badge-sable {
  background-color: #FFF !important;
  color: #76501c !important;
  border-color: #76501c !important;
}

html {
  line-height: 1.15;
  min-height: 100%;
}

body {
  font-family: "Source Sans Pro", "Calibri", "Arial", sans-serif;
  background-color: #fff;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  min-height: 100%;
}

body.fixed .header {
  top: 0;
  width: 100%;
  height: 81px;
  position: fixed;
}

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Grid                                                                              */
/* ///////////////////////////////////////////////////////////////////////////////////////*/
.wrapper {
  position: relative;
  padding-top: 220px;
  height: 100%;
}

.header {
  z-index: 10;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #FFF;
  background-image: url("../img/ComfyUI_01187_.png");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 220px;
}
.header .main-header {
  font-family: "Dosis", "Calibri", "Arial";
  text-transform: uppercase;
}
.header .main-header .header-logo {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  overflow: hidden;
  margin: 0;
  padding: 0 1rem 0 3rem;
  background-color: #FFF;
}
.header .main-header .header-logo .logo {
  font-size: 1.4em;
  font-weight: 300;
  color: rgb(0, 0, 0);
}

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      navigation                                                                        */
/* ///////////////////////////////////////////////////////////////////////////////////////*/
ul.main-navigation {
  position: absolute;
  bottom: 0;
  list-style: none;
  padding: 0;
  margin: 0 0 0 3rem;
  display: flex;
  border-style: solid;
  border-color: #ccc;
  border-width: 0 0 1px 0;
}
ul.main-navigation li a {
  display: block;
  padding: 0.5rem 1rem;
  border-style: solid;
  border-color: #ccc;
  border-width: 0 1px 0 0;
  color: #000;
  font-size: 0.9em;
  text-transform: uppercase;
  background-color: #DFDFDF;
}
ul.main-navigation li a.nav-btn-search {
  background-color: #fb8a00;
  border-color: #fb8a00;
  color: #FFF;
}
ul.main-navigation li a.sel {
  background-color: #FFF;
}
ul.main-navigation li:first-child a {
  border-width: 0 1px 0 1px;
}
ul.main-navigation .search-bar-cont {
  position: relative;
  overflow: hidden;
  width: 105px;
  transition: width 200ms ease-out;
}
ul.main-navigation .search-bar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  padding: 0.3rem 0.6rem;
  border-style: solid;
  border-width: 0 1px 0 0;
  font-size: 0.9em;
  text-transform: uppercase;
  background-color: #fb8a00;
  border-color: #fb8a00;
  color: #FFF;
}
ul.main-navigation .search-bar input[type=text] {
  border: 2px solid #FFF;
}
ul.main-navigation .search-bar .search-bar-btn {
  padding: 0 1rem;
}
ul.main-navigation .search-bar .search-bar-btn:focus {
  outline-color: #fb8a00;
  outline-style: none;
  border-color: #fb8a00;
}

ul.sous-navigation {
  position: relative;
  list-style: none;
  padding: 0;
  margin: -1rem 0 1rem 0;
  display: flex;
  border-style: solid;
  border-color: #CCC;
  border-width: 0 0 1px 0;
}
ul.sous-navigation li a {
  position: relative;
  display: block;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-style: solid;
  border-color: #FFF;
  border-width: 1px 1px 0 1px;
  border-radius: 4px 4px 0 0;
  color: #000;
  font-size: 0.8em;
  text-transform: uppercase;
}
ul.sous-navigation li a.sel {
  top: 1px;
  border-color: #ccc;
  font-weight: 900;
  background-color: #FFF;
}

ul.order-filter {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0 0 1rem 0;
  display: flex;
}
ul.order-filter li a {
  position: relative;
  display: block;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  color: #888;
  font-size: 0.9em;
  cursor: pointer;
}
ul.order-filter li a.sortable-active {
  color: #000;
}
ul.order-filter li a:after {
  display: block;
  position: absolute;
  left: 0;
  top: 0.5rem;
  color: #0a5f84;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0dc";
  opacity: 0.5;
}
ul.order-filter li a.sortable-active:after {
  content: "\f160";
  opacity: 1;
}
ul.order-filter li a.sortable-active.sortable-desc:after {
  content: "\f884";
}
/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Fiche                                                                          */
/* ///////////////////////////////////////////////////////////////////////////////////////*/
/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Categorie                                                                         */
/* ///////////////////////////////////////////////////////////////////////////////////////*/
.category-handle {
  position: relative;
  cursor: pointer;
}
.category-handle:before {
  display: block;
  position: absolute;
  left: -20px;
  top: 0;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0da";
  opacity: 0.5;
}
.category-handle.open:before {
  content: "\f0d7";
}

.category-list {
  height: 0;
  visibility: hidden;
}
.category-list.open {
  height: auto;
  visibility: visible;
}

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Layout                                                                            */
/* ///////////////////////////////////////////////////////////////////////////////////////*/
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  grid-auto-rows: minmax(100px, auto);
}
.wrapper > .content {
  padding: 2rem 3rem;
}
.wrapper > .categories {
  background-color: #DFDFDF;
  padding: 2rem 3rem;
  height: 100%;
}
.wrapper > .categories .category-sel {
  position: relative;
  font-weight: 900;
}
.wrapper > .categories .category-sel:before {
  display: block;
  position: absolute;
  content: "\f00c";
  font-size: 1rem;
  color: #0a5f84;
  font-weight: 900;
  top: 0;
  left: -20px;
  font-family: "Font Awesome 6 Free";
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -7.5px;
  margin-left: -7.5px;
}
.row.row-gutter-double > div:first-child {
  padding-right: 15px;
  padding-left: 7.5px;
}
.row.row-gutter-double > div:last-child {
  padding-right: 7.5px;
  padding-left: 15px;
}
.row.row-gutter-double > div {
  padding-right: 15px;
  padding-left: 15px;
}
.row.row-gutter-double.row-gutter-line > div:last-child {
  border-right-width: 0;
}
.row.row-gutter-double.row-gutter-line > div {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.row > div {
  position: relative;
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
}
.row .col-1, .row .col-md-1, .row .col-sm-1, .row .col-xs-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
.row .col-2, .row .col-md-2, .row .col-sm-2, .row .col-xs-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.row .col-3, .row .col-md-3, .row .col-sm-3, .row .col-xs-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.row .col-4, .row .col-md-4, .row .col-sm-4, .row .col-xs-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.row .col-5, .row .col-md-5, .row .col-sm-5, .row .col-xs-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.row .col-6, .row .col-md-6, .row .col-sm-6, .row .col-xs-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.row .col-7, .row .col-md-7, .row .col-sm-7, .row .col-xs-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
.row .col-8, .row .col-md-8, .row .col-sm-8, .row .col-xs-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.row .col-9, .row .col-md-9, .row .col-sm-9, .row .col-xs-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.row .col-10, .row .col-md-10, .row .col-sm-10, .row .col-xs-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.row .col-11, .row .col-md-11, .row .col-sm-11, .row .col-xs-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
.row .col-12, .row .col-md-12, .row .col-sm-12, .row .col-xs-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.multi-column {
  column-count: 3;
}

/*/////////////////////////////////////////////////////////*/
/* liste navigation */
.list-navigation {
  display: flex;
  justify-content: space-between;
}

.list-quantity .form-control {
  height: calc(2.7rem + 2px);
}

.list-navigation-display .btn-display {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #CCC;
  width: 42px;
  height: 42px;
  border: solid 1px #CCC;
  background-color: #FFF;
  border-radius: 4px;
}
.list-navigation-display .btn-display.actif {
  background-color: #ccc;
  color: #FFF;
}

.list-pagination-btns {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
}

.table-btn-order {
  position: relative;
  color: #555;
}
.table-btn-order.sel {
  color: #000;
}
.table-btn-order.desc::before {
  display: block;
  position: absolute;
  content: "\f884";
  font-size: 1rem;
  color: #555;
  font-weight: 900;
  top: 0;
  right: -20px;
  font-family: "Font Awesome 6 Free";
}
.table-btn-order.asc::before {
  display: block;
  position: absolute;
  content: "\f160";
  font-size: 1rem;
  color: #555;
  font-weight: 900;
  top: 0;
  right: -20px;
  font-family: "Font Awesome 6 Free";
}

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Components                                                                        */
/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*--------------------------------  Nav bar  -----------------------------------------    */
.nav-bar {
  position: relative;
  height: 220px;
  background-color: rgba(52, 61, 61, 0.13);
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}
.nav-bar > ul {
  padding: 0;
  margin: 0;
}
.nav-bar > ul li {
  list-style: none;
  display: inline-block;
}
.nav-bar > ul li a {
  text-decoration: none;
  font-size: 0.9rem;
  color: #000;
}
.nav-bar h1 {
  font-size: 2rem;
  padding-top: 0.5rem;
  padding-left: 2rem;
  font-weight: 300;
}

/*--------------------------------  sous bar  -----------------------------------------    */
.sous-nav {
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
  top: 0;
  padding: 0.8rem 0 0 1.25rem;
  height: 54px;
  background-color: rgba(52, 61, 61, 0.08);
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}
.sous-nav ul {
  display: flex;
  padding: 0;
  margin: 0;
}
.sous-nav ul li {
  list-style: none;
  display: inline-block;
  position: relative;
  top: 1px;
}
.sous-nav ul li a {
  padding: 0.6rem 1rem 0.4rem 1rem;
  display: block;
  text-decoration: none;
  color: #0a5f84;
  font-family: "Dosis", "Calibri", "Arial";
  font-weight: 500;
  font-size: 1rem;
  margin-right: 0.5rem;
  border: solid 1px transparent;
  border-bottom: none;
}
.sous-nav ul li.active a {
  color: #000;
  border-color: rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

/*--------------------------------  nav column  -----------------------------------------  */
.nav-column {
  position: relative;
  background-color: #323b3b;
}
.nav-column .logo {
  display: block;
  position: relative;
  width: 100%;
  height: 220px;
  background-color: #FFF;
  font-family: "Dosis", "Calibri", "Arial";
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  font-weight: 300;
  padding: 0.4rem 0.7rem;
  font-size: 1.8rem;
}
.nav-column .logo span {
  color: #00e7ff;
}
.nav-column .user-panel {
  padding: 0.6rem 1rem;
  font-family: "Dosis", "Calibri", "Arial";
  position: relative;
  display: flex;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  height: 55px;
}
.nav-column .user-panel a {
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.03rem;
}
.nav-column .user-panel a small {
  padding-left: 1rem;
  color: #00e7ff;
}
.nav-column .user-panel .user-picto {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  margin-right: 0.7rem;
  color: #00e7ff;
  border: solid 2px #00e7ff;
  border-radius: 50%;
}
.nav-column .sidebar nav {
  font-family: "Dosis", "Calibri", "Arial";
  font-weight: 500;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
}
.nav-column .sidebar nav > ul > li.nav-item > a {
  padding: 0.6rem 1rem;
}
.nav-column .sidebar nav > ul > li.nav-item > a > i, .nav-column .sidebar nav > ul > li.nav-item > a > p > i {
  top: 0.6rem;
}
.nav-column .sidebar nav > ul > li.nav-item > ul.nav-treeview > li > a {
  padding-left: 1.5rem;
}
.nav-column .sidebar nav > ul > li.nav-item > ul.nav-treeview > li > a > i.fa,
.nav-column .sidebar nav > ul > li.nav-item > ul.nav-treeview > li > a > i.fas {
  left: 1.5rem;
}
.nav-column .sidebar nav ul {
  padding: 0;
  margin: 0;
}
.nav-column .sidebar nav ul li {
  position: relative;
  list-style: none;
}
.nav-column .sidebar nav ul li.nav-header {
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.4);
  padding: 1.2rem 1rem;
  letter-spacing: 0.1em;
}
.nav-column .sidebar nav ul p {
  display: inline-block;
  margin: 0;
  padding-left: 1.6rem;
}
.nav-column .sidebar nav ul a {
  display: block;
  padding: 0.3rem 1rem;
  color: inherit;
}
.nav-column .sidebar nav ul i {
  position: absolute;
  left: 1rem;
  top: 0.3rem;
  font-size: 1rem;
}
.nav-column .sidebar nav ul i.right {
  left: auto;
  right: 0.5rem;
  transition: transform ease-out 300ms;
}
.nav-column .sidebar nav ul .nav-treeview {
  overflow: hidden;
  height: 0;
  transition: height ease-out 200ms;
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-column .sidebar nav ul .menu-open i.right {
  transform: rotate(-90deg);
}
.nav-column .sidebar nav ul .active {
  color: rgba(255, 255, 255, 0.8);
}
.nav-column .sidebar nav ul .active > a i {
  color: #00e7ff;
}
.nav-column .sidebar nav ul .active > a {
  background-color: rgba(0, 0, 0, 0.2);
}
.nav-column .sidebar nav ul .sel > a i {
  color: #00e7ff;
}

/*--------------------------------  Breadcrumb  -----------------------------------------  */
.breadcrumb-cont {
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
}

.sous-nav + .breadcrumb-cont {
  margin-top: 40px;
}

.breadcrumb {
  padding-top: 0.5rem;
  font-family: "Dosis", "Calibri", "Arial";
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.6rem;
}
.breadcrumb li {
  display: inline-block;
  list-style: none;
}
.breadcrumb li a {
  color: #009fac;
}
.breadcrumb li:after {
  display: inline-block;
  padding: 0 10px;
  content: "/";
}
.breadcrumb li:last-child:after {
  display: none;
}

/*--------------------------------  pagination  -----------------------------------------  */
.pagination {
  border: solid 1px #CCC;
  margin: 0;
  padding: 0;
  display: inline-flex;
  width: auto;
  border-radius: 4px;
}
.pagination li {
  list-style: none;
  border-right: solid 1px #CCC;
}
.pagination li a, .pagination li span {
  display: block;
  padding: 0.5rem 0.8rem;
  color: #555;
}
.pagination li.active span {
  background-color: #CCC;
  color: #FFF;
}
.pagination li:last-child {
  border-right: 0;
}

/*--------------------------------  list nav  -----------------------------------------  */
.list-nav {
  padding: 0;
  margin: 0;
}
.list-nav li {
  list-style: none;
}
.list-nav li input {
  display: none;
}
.list-nav li label {
  display: block;
  padding: 0.5rem 0.85rem 0.5rem 2rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}
.list-nav li label .badge {
  font-size: 0.7rem;
}
.list-nav li label:before {
  top: 0.5rem;
}

/*--------------------------------  user list  -----------------------------------------  */
.user-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
  padding: 0;
}
.user-list li {
  list-style: none;
  text-align: center;
  margin: 0.5rem;
}
.user-list .user-list-item {
  font-size: 0.9rem;
  width: 100px;
  color: #0a5f84;
  display: block;
}
.user-list .user-list-item .user-list-img {
  display: inline-block;
  width: 100px;
  padding-top: 100px;
  height: 0;
  background-color: rgba(0, 0, 0, 0.2);
  background-size: cover;
  border-radius: 50%;
}
.user-list .user-list-item .user-list-name {
  display: block;
  font-weight: 600;
}
.user-list .user-list-item user-list-date {
  display: block;
}

/*--------------------------------  Alert  -----------------------------------------  */
.alert-cont {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  width: 33%;
  height: 0;
  transition: height ease-out 300ms;
}
.alert-cont .alert {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  border-radius: 0 0 0 8px;
  background-color: #66f1ff;
  color: #FFF;
  font-size: 0.8rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
.alert-cont .alert h5 {
  font-size: 1rem;
}
.alert-cont .alert button {
  color: #FFF;
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  border: 0;
  background-color: transparent;
}
.alert-cont .alert.alert-danger {
  border-color: #d8002c;
  background-color: #d8002c;
}
.alert-cont .alert.alert-info {
  border-color: #00a1ad;
  background-color: #00a1ad;
}
.alert-cont .alert.alert-success {
  border-color: #56ad00;
  background-color: #56ad00;
}
.alert-cont .alert.alert-warning {
  border-color: #fba900;
  background-color: #fba900;
}
.alert-cont .alert.alert-link {
  border-color: #0a5f84;
  background-color: #0a5f84;
}
.alert-cont .alert.alert-primary {
  border-color: #0062bf;
  background-color: #0062bf;
}
.alert-cont .alert.alert-default {
  border-color: #c4cece;
  background-color: #c4cece;
}

/*--------------------------------  DialogBox  -----------------------------------------  */
.dialog-box-cloud {
  display: flex;
  position: fixed;
  overflow: hidden;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.dialog-box {
  position: relative;
  display: block;
  padding: 20px;
  background-color: #FFF;
  width: 450px;
  height: 150px;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}
.dialog-box .dialog-box-message {
  font-size: 1rem;
  padding-left: 40px;
}
.dialog-box .dialog-box-footer {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 20px;
  text-align: right;
}
.dialog-box .btn {
  color: #FFF !important;
}
.dialog-box .btn + .btn {
  margin-left: 20px;
}
.dialog-box:after {
  position: absolute;
  display: block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f06a";
  color: #fba900;
  font-size: 2em;
  top: 0;
  left: 10px;
}

/*--------------------------------  Card  -----------------------------------------  */
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.13), 0 1px 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
}
.card .card-outline.bg-default {
  color: inherit;
  background-color: #FFF !important;
  border-top: 3px solid #c4cece;
}
.card .card-outline.bg-info {
  color: inherit;
  background-color: #FFF !important;
  border-top: 3px solid #00a1ad;
}
.card .card-outline.bg-primary {
  color: inherit;
  background-color: #FFF !important;
  border-top: 3px solid #0062bf;
}
.card .card-outline.bg-warning {
  color: inherit;
  background-color: #FFF !important;
  border-top: 3px solid #fba900;
}
.card .card-outline.bg-danger {
  color: inherit;
  background-color: #FFF !important;
  border-top: 3px solid #d8002c;
}
.card .card-outline.bg-success {
  color: inherit;
  background-color: #FFF !important;
  border-top: 3px solid #56ad00;
}
.card .card-outline.card-default {
  color: inherit;
  background-color: #FFF !important;
  border-top: 3px solid #c4cece;
}
.card .card-outline.card-info {
  color: inherit;
  border-top: 3px solid #00a1ad;
}
.card .card-outline.card-primary {
  color: inherit;
  border-top: 3px solid #0062bf;
}
.card .card-outline.card-warning {
  color: inherit;
  border-top: 3px solid #fba900;
}
.card .card-outline.card-danger {
  color: inherit;
  border-top: 3px solid #d8002c;
}
.card .card-outline.card-success {
  color: inherit;
  border-top: 3px solid #56ad00;
}
.card .card-header {
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 1.25rem;
  position: relative;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card .card-filter:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card .card-filter {
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card .card-filter .input-group:last-child {
  margin: 0;
}
.card .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  color: inherit;
}
.card .card-zone {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0 7.5px;
  color: inherit;
}
.card .card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.04);
  font-size: 0.9rem;
  color: #666;
}
.card .card-nav {
  padding: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card .card-title {
  float: left;
  font-size: 1.1rem;
  font-weight: 600;
  color: inherit;
  margin: 0;
}
.card .upper-title {
  margin: 0;
  font-family: "Dosis", "Calibri", "Arial";
  font-weight: 600;
}
.card.card-user .card-header {
  padding: 1rem;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  min-height: 135px;
  padding-bottom: 50px;
  margin-bottom: 45px;
}
.card.card-user .card-header h3 {
  margin: 0;
}
.card.card-user .user-image {
  left: 50%;
  margin-left: -45px;
  position: absolute;
  bottom: -45px;
}
.card.card-user .user-image img {
  width: 90px;
  overflow: hidden;
  border-radius: 50%;
  border: solid 2px #FFF;
}
.card.card-user .user-symbol {
  left: 50%;
  margin-left: -30px;
  position: absolute;
  top: 105px;
}
.card.card-user .user-symbol .symbol {
  display: flex;
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  border: solid 2px #FFF;
  background-color: #CCC;
  color: #FFF;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
}
.card.card-user .user-image + .card-body {
  padding-top: 60px;
}
.card.card-user .user-symbol + .card-body {
  /*padding-top: 40px;*/
}
.card.card-user .card-user-info:after {
  display: block;
  content: "✽✽✽";
  color: #00a1ad;
  margin: 0.5rem 0;
}
.card.card-user .card-user-list {
  padding: 0;
  margin: 0;
}
.card.card-user .card-user-list .card-user-list-item {
  border-top: solid 1px rgba(0, 0, 0, 0.2);
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  list-style: none;
}
.card.card-user .card-user-list .card-user-list-item:first-child {
  border: none;
}
.card.card-fusion {
  margin-bottom: 0;
  border-radius: 0.25rem 0.25rem 0 0;
  border-bottom: 0;
}
.card.card-fusion + .card {
  border-top: none;
  border-top: dashed 1px rgba(0, 0, 0, 0.125);
  border-radius: 0 0 0.25rem 0.25rem;
}
.card.card-light {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.card.card-light .card-header {
  border: 0;
}
.card.card-light .card-body {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0 6px 6px 6px;
  background-color: #FFF;
}
.card.card-light .nav-tabs {
  padding-left: 0;
}

.card-body-color-article {
  background-color: #eef1f3;
}
.card-body-color-article h6 {
  color: #135e93;
}

.card-zone {
  margin-left: 3.75px;
  margin-right: 3.75px;
}

.zone {
  border-radius: 0.25rem 0 0 0.25rem;
  padding: 7.5px !important;
  background-color: rgba(109, 143, 144, 0.1);
  border-right: dashed 1px;
  border-color: rgba(109, 143, 144, 0.3);
}
.zone .zone-title {
  color: #6d8f90;
}
.zone:last-child {
  border-right: none;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.zone.zone-yellow {
  background-color: rgba(181, 134, 14, 0.1);
  border-color: rgba(181, 134, 14, 0.2);
}
.zone.zone-yellow .zone-title {
  color: #b5860e;
}
.zone.zone-blue {
  background-color: rgba(14, 132, 181, 0.1);
  border-color: rgba(14, 132, 181, 0.2);
}
.zone.zone-blue .zone-title {
  color: #0e84b5;
}
.zone.zone-purple {
  background-color: rgba(156, 14, 181, 0.1);
  border-color: rgba(156, 14, 181, 0.2);
}
.zone.zone-purple .zone-title {
  color: #9c0eb5;
}

.zone + .zone {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.zone-title {
  font-size: 1.1rem;
  font-weight: 500;
}

/*--------------------------------  nav tab  -----------------------------------------  */
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-tabs {
  position: relative;
  top: 1px;
  padding-top: 0.3rem;
  padding-left: 0.3rem;
  font-family: "Dosis", "Calibri", "Arial";
  font-weight: 500;
}
.nav-tabs .nav-link {
  display: block;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #0a5f84;
}
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs.nav-tabs-bechamel {
  height: 36px;
  padding-top: 0.1rem;
  background: linear-gradient(#DAE2E5, #b8c8cd);
}
.nav-tabs.nav-tabs-bechamel .nav-link {
  font-size: 0.9rem;
  padding: 6px 1rem 1px 1rem;
}
.nav-tabs.nav-tabs-bechamel .nav-link a {
  color: #000;
  font-weight: 600;
  font-family: "Abel";
}

.tabs-content {
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.13), 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 1.25rem;
}
.tabs-content.tabs-content-bechamel {
  box-shadow: none;
}
.tabs-content.tabs-content-bechamel .tab-content {
  border: none;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .tab-pane.active {
  display: block;
}

.card-header.bg-primary:not(.card-outline) > .nav-tabs a.nav-link {
  color: #fff;
}
.card-header.bg-primary:not(.card-outline) > .nav-tabs a.nav-link.active {
  color: #495057;
}

.card-nav.bg-primary:not(.card-outline) > .nav-tabs a.nav-link {
  color: #fff;
}
.card-nav.bg-primary:not(.card-outline) > .nav-tabs a.nav-link.active {
  color: #495057;
}

.tab-block .nav-tabs {
  padding: 0;
}
.tab-block .tab-content {
  background-color: #FFF;
  padding: 0.75rem;
  border: 1px solid #dee2e6;
  border-radius: 0 0.25rem 0.25rem 0.25rem;
}

/*--------------------------------  Button  -----------------------------------------  */
.btn, .cartouche {
  display: inline-block;
  margin-bottom: 0;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: transparent;
  border: 0;
  color: inherit;
}
.btn.disabled, .btn:disabled, .cartouche.disabled, .cartouche:disabled {
  cursor: not-allowed;
}
.btn.btn-link, .cartouche.btn-link {
  background-color: transparent;
  padding: 0;
  border: 0;
  color: #0a5f84;
}
.btn.btn-link:hover, .cartouche.btn-link:hover {
  color: #111;
}
.btn.btn-default, .cartouche.btn-default {
  background-color: #c4cece;
  color: #111;
}
.btn.btn-primary, .cartouche.btn-primary {
  background-color: #0062bf;
  color: #FFF;
}
.btn.btn-success, .cartouche.btn-success {
  background-color: #56ad00;
  color: #FFF;
}
.btn.btn-info, .cartouche.btn-info {
  background-color: #00a1ad;
  color: #FFF;
}
.btn.btn-warning, .cartouche.btn-warning {
  background-color: #fba900;
  color: #111;
}
.btn.btn-danger, .cartouche.btn-danger {
  background-color: #d8002c;
  color: #FFF;
}
.btn.btn-outline, .cartouche.btn-outline {
  padding: 5px 11px;
  background-color: transparent !important;
  border: solid 1px #c4cece;
  color: #333;
}
.btn.btn-outline.btn-default, .cartouche.btn-outline.btn-default {
  border-color: #c4cece;
  color: #333;
}
.btn.btn-outline.btn-primary, .cartouche.btn-outline.btn-primary {
  border-color: #0062bf;
  color: #0062bf;
}
.btn.btn-outline.btn-success, .cartouche.btn-outline.btn-success {
  border-color: #56ad00;
  color: #56ad00;
}
.btn.btn-outline.btn-info, .cartouche.btn-outline.btn-info {
  border-color: #00a1ad;
  color: #00a1ad;
}
.btn.btn-outline.btn-warning, .cartouche.btn-outline.btn-warning {
  border-color: #fba900;
  color: #fba900;
}
.btn.btn-outline.btn-danger, .cartouche.btn-outline.btn-danger {
  border-color: #d8002c;
  color: #d8002c;
}
.btn.btn-lg, .cartouche.btn-lg {
  padding: 9px 18px;
  font-size: 1.1rem;
}
.btn.btn-sm, .cartouche.btn-sm {
  padding: 2px 12px;
  font-size: 0.8rem;
}
.btn.btn-xs, .cartouche.btn-xs {
  padding: 2px 9px;
  font-size: 0.7rem;
}
.btn.btn-outline.btn-lg, .cartouche.btn-outline.btn-lg {
  padding: 8px 17px;
}
.btn.btn-outline.btn-sm, .cartouche.btn-outline.btn-sm {
  padding: 1px 11px;
}
.btn.btn-outline.btn-xs, .cartouche.btn-outline.btn-xs {
  padding: 1px 8px;
}
.btn.btn-flat, .cartouche.btn-flat {
  border-radius: 0;
}
.btn.btn-disabled, .cartouche.btn-disabled {
  opacity: 0.5;
}

.btn + .btn, .btn + form {
  margin-left: 5px;
}

.cartouche {
  cursor: default;
}

/*--------------------------------------------------------------------------------------------------------------------------*/
.form-group, .form-group-inline {
  margin-bottom: 1rem;
}
.form-group label, .form-group-inline label {
  display: inline-block;
  margin-bottom: 0.3rem;
  font-size: 0.9rem;
}
.form-group label:not(.check-label), .form-group-inline label:not(.check-label) {
  font-weight: 600;
}

.form-group-inline {
  display: flex;
  flex-wrap: wrap;
}
.form-group-inline label {
  flex-basis: 33%;
  margin-bottom: 0.75rem;
  padding: 0.5rem 0.75rem 0 0;
}
.form-group-inline .form-control {
  flex-basis: 66%;
  width: auto;
  margin-bottom: 0.75rem;
}

.input-group {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  margin-bottom: 1rem;
}
.input-group .form-control {
  position: relative;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group .input-group-append {
  margin-left: -1px;
}
.input-group .input-group-append .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group .input-group-prepend {
  margin-right: -1px;
}
.input-group .input-group-prepend .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group .input-group-append, .input-group .input-group-prepend {
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-group.input-group-sm .form-control {
  height: calc(1.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
}
.input-group.input-group-sm .btn {
  padding: 0.27rem 0.75rem;
  font-size: 0.9rem;
}
.input-group.input-group-xs .form-control {
  height: calc(1.3rem + 2px);
  padding: 0.2rem 0.75rem;
  font-size: 0.8rem;
}
.input-group.input-group-xs .btn {
  padding: 0 0.5rem;
  font-size: 0.7rem;
  line-height: 1;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s;
}
.form-control:focus {
  outline: 0;
  border-color: #0a5f84;
}
.form-control::placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.form-control option {
  font-size: 0.9rem;
  padding: 0.3rem 0.1rem;
}
.form-control[disabled] {
  opacity: 0.4;
  background-color: rgba(0, 0, 0, 0.1);
}
.form-control.form-control-sm {
  height: calc(1.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
}
.form-control.form-control-xs {
  height: calc(1.3rem + 2px);
  padding: 0.2rem 0.75rem;
  font-size: 0.8rem;
}
.form-control.form-control-inline {
  display: inline-block;
  width: auto;
}

.has-error .form-control {
  border-color: #d8002c;
}
.has-error .label-error {
  display: block;
  color: #d8002c;
  font-size: 0.9rem;
  margin: 0;
}
.has-error .input-group ~ .label-error {
  margin-top: -1rem;
}

textarea.form-control {
  height: auto;
}

select.form-control[multiple], select.form-control[size] {
  height: auto;
}

.form-check, .form-check-inline {
  position: relative;
  display: block;
}
.form-check input:disabled ~ label, .form-check input[disabled] ~ label, .form-check-inline input:disabled ~ label, .form-check-inline input[disabled] ~ label {
  color: #6c757d;
}
.form-check label, .form-check-inline label {
  font-weight: 400 !important;
}
.form-check input[type=checkbox], .form-check input[type=radio], .form-check-inline input[type=checkbox], .form-check-inline input[type=radio] {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

textarea.form-control {
  height: auto;
}

/*------------------------------------------------*/
label.checkbox {
  position: relative;
  display: inline-block;
  margin: 0;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0 0 0 2rem;
}
label.checkbox.checkbox-opacity {
  opacity: 0.5;
}
label.checkbox.checkbox-crossed {
  text-decoration: line-through;
}
label.checkbox .message-on {
  display: none;
}
label.checkbox .message-off {
  display: inline;
}
label.checkbox:before {
  display: block;
  content: "\f0c8";
  position: absolute;
  color: #BBB;
  font-family: "Font Awesome 5 Free";
  font-weight: 500;
  top: 0;
  left: 0.7rem;
}

input:checked + label.checkbox.checkbox-opacity {
  opacity: 1;
}
input:checked + label.checkbox.checkbox-crossed {
  text-decoration: none;
}
input:checked + label.checkbox .message-on {
  display: inline;
}
input:checked + label.checkbox .message-off {
  display: none;
}
input:checked + label.checkbox:before {
  content: "\f14a";
  color: #0062bf;
  font-weight: 900;
}

label.radio {
  position: relative;
  display: inline-block;
  margin: 0;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0 0 0 2rem;
}
label.radio.radio-opacity {
  opacity: 0.5;
}
label.radio.radio-crossed {
  text-decoration: line-through;
}
label.radio .message-on {
  display: none;
}
label.radio .message-off {
  display: inline;
}
label.radio:before {
  display: block;
  content: "\f111";
  position: absolute;
  color: #BBB;
  font-family: "Font Awesome 5 Free";
  font-weight: 500;
  top: 0;
  left: 0.7rem;
}

input:checked + label.radio.radio-opacity {
  opacity: 1;
}
input:checked + label.radio.radio-crossed {
  text-decoration: none;
}
input:checked + label.radio .message-on {
  display: inline;
}
input:checked + label.radio .message-off {
  display: none;
}
input:checked + label.radio:before {
  content: "\f111";
  color: #0062bf;
  font-weight: 900;
}

.input-color {
  display: inline-block;
  width: 100%;
  border-radius: 4px;
}
.input-color input[type=color] {
  position: relative;
  padding: 0;
  border: 0;
  opacity: 0;
}

/*----------------------------------------------------------------------*/
/*------------------      Form Xtras          --------------------------*/
/*----------------------------------------------------------------------*/
/* select xtras */
.input-group .select-x-container {
  display: inline-block;
}
.input-group .select-x-container .select-x-zone {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.select-x-container {
  position: relative;
  display: block;
  width: 100%;
}
.select-x-container .container-option {
  display: none;
  position: absolute;
  left: 0;
  top: 38px;
  width: 100%;
}
.select-x-container .container-option .zone-option {
  padding: 0.5rem;
  display: block;
  width: 100%;
  background-color: #FFF;
  border-radius: 0 0 4px 4px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-top: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
}
.select-x-container .container-option .zone-option .search {
  width: 100%;
}
.select-x-container .container-option .zone-option .list-options {
  padding: 0;
  font-size: 0.9rem;
  overflow-y: auto;
  max-height: 150px;
  margin: 5px 0 0 0;
}
.select-x-container .container-option .zone-option .list-options li {
  list-style: none;
  padding: 0.2rem 0.5rem;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.select-x-container .container-option .zone-option .list-options li:first-child {
  border-top: none;
}
.select-x-container .container-option .zone-option .list-options li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.select-x-container .container-option .create-new-option {
  display: none;
}
.select-x-container .container-option .create-new-option.show {
  display: block;
}
.select-x-container .select-x-zone {
  position: relative;
  display: flex;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.select-x-container .select-x-zone .zone-label {
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  flex-grow: 1;
}
.select-x-container .select-x-zone .zone-arrow {
  border-left: solid 1px rgba(0, 0, 0, 0.2);
  width: 30px;
  margin: -0.375rem -0.75rem -0.375rem 0;
}
.select-x-container .select-x-zone .zone-arrow:after {
  padding: 6px 9px;
  display: block;
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  font-weight: 900;
}
.select-x-container .select-x-zone .zone-reset {
  display: none;
  border-left: solid 1px rgba(0, 0, 0, 0.2);
  margin: -0.375rem 0 -0.375rem 0;
  width: 30px;
}
.select-x-container .select-x-zone .zone-reset:after {
  padding: 9px 9px;
  display: block;
  font-family: "Font Awesome 5 Free";
  font-size: 0.8rem;
  content: "\f00d";
  font-weight: 900;
}
.select-x-container.open .zone-arrow:after {
  content: "\f106";
}
.select-x-container.open .container-option {
  display: block;
}
.select-x-container.open .select-x-zone {
  border-radius: 4px 4px 0 0;
}
.select-x-container.selected .zone-label {
  color: #000;
}
.select-x-container.selected .zone-reset {
  display: block;
}

.select-x-multiple {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 4px 4px 0 0;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-bottom: none;
}
.select-x-multiple .select-x-multiple-item {
  overflow: hidden;
  position: relative;
  margin-right: 3px;
  margin-bottom: 3px;
  padding-right: 20px;
}
.select-x-multiple .select-x-multiple-item .select-x-multiple-item-remove {
  position: absolute;
  display: flex;
  width: 16px;
  height: 100%;
  top: 0;
  right: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.select-x-multiple .select-x-multiple-item .select-x-multiple-item-remove:before {
  display: block;
  font-family: "Font Awesome 6 Free";
  content: "\f057";
}

.has-error .select-x-zone {
  border-color: #d8002c;
}

/*----------------------------------------------------------------------*/
/*------------------      Form order field    --------------------------*/
/*----------------------------------------------------------------------*/
.order-field-container {
  list-style: none;
  padding: 0;
}
.order-field-container:empty {
  margin: 0;
}
.order-field-container li {
  position: relative;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 0.5rem;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.6666666667);
}
.order-field-container li.new {
  border-color: #0056b3;
  color: #0056b3;
}
.order-field-container li.new:before {
  position: absolute;
  top: 0;
  right: 25px;
  display: block;
  background: #0056b3;
  color: #FFF;
  width: 45px;
  height: 10px;
  font-size: 0.6em;
  line-height: 0.8;
  content: "nouveau";
  text-align: center;
}
.order-field-container li .order-field-middle {
  flex-grow: 1;
  padding: 2px 5px;
}
.order-field-container li .order-field-action {
  flex-basis: 25px;
  position: relative;
  width: 25px;
  border-left: solid 1px rgba(0, 0, 0, 0.2);
}
.order-field-container li .order-field-action .order-field-delete {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 100%;
  color: #dc0000;
}
.order-field-container li .order-field-action .order-field-delete:hover {
  background-color: #dc0000;
  color: #FFF;
}
.order-field-container li .order-field-action .order-field-delete:before {
  top: 0;
  left: 0;
  display: block;
  width: 25px;
  height: auto;
  font-family: "Font Awesome 6 Free";
  font-weight: bold;
  font-size: 0.7em;
  content: "\f00d";
  text-align: center;
}
.order-field-container li .order-field-up-down {
  flex-basis: 25px;
  position: relative;
  width: 25px;
  border-right: solid 1px rgba(0, 0, 0, 0.2);
}
.order-field-container li .order-field-up-down .order-field-btn-up, .order-field-container li .order-field-up-down .order-field-btn-down {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 50%;
}
.order-field-container li .order-field-up-down .order-field-btn-up:hover, .order-field-container li .order-field-up-down .order-field-btn-down:hover {
  background-color: #0056b3;
  color: #FFF;
}
.order-field-container li .order-field-up-down .order-field-btn-up:before, .order-field-container li .order-field-up-down .order-field-btn-down:before {
  top: 0;
  left: 0;
  display: block;
  width: 25px;
  height: auto;
  font-family: "Font Awesome 6 Free";
  font-weight: bold;
  font-size: 0.7em;
  content: "\f077";
  text-align: center;
}
.order-field-container li .order-field-up-down .order-field-btn-down {
  border-top: solid 1px rgba(0, 0, 0, 0.2);
}
.order-field-container li .order-field-up-down .order-field-btn-down:before {
  content: "\f078";
}
.order-field-container li .order-field-up-down .order-field-btn-up + .order-field-btn-down {
  top: 50%;
  height: 50%;
}
.order-field-container li.first .order-field-btn-down {
  height: 100%;
  border-top: none;
}
.order-field-container li.last .order-field-btn-up {
  height: 100%;
}
.order-field-container span.item-option {
  position: relative;
  margin: 0 -5px -2px -5px;
  display: block;
  border-top: solid 1px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.03);
  padding: 5px;
}
.order-field-container span.item-option:empty {
  display: none;
}
.order-field-container span.item-option select option:checked {
  background-color: rgba(0, 0, 0, 0.05);
}
.order-field-container span.item-option select.empty {
  background-color: rgba(0, 0, 0, 0.02);
  color: #888;
}
.order-field-container span.item-option select.empty option {
  color: #000;
}

/*----------------------------------------------------------------------*/
/*------------------      Form Geo            --------------------------*/
/*----------------------------------------------------------------------*/
.form-autocomplete {
  position: absolute;
  width: 100%;
}
.form-autocomplete ul {
  font-size: 0.9rem;
  padding: 0;
  margin: 0;
  background-color: #FFF;
  border: solid 1px #DDD;
  border-top: none;
  border-radius: 0 0 6px 6px;
}
.form-autocomplete ul li {
  list-style: none;
  padding: 3px 0.75rem;
  cursor: pointer;
  border-bottom: solid 1px #DDD;
}
.form-autocomplete ul li:hover {
  background-color: #EEE;
}
.form-autocomplete ul li em {
  float: right;
  color: #777;
}
.form-autocomplete ul li:last-child {
  border-radius: 0 0 6px 6px;
}

input:focus + .form-autocomplete ul {
  border-color: #0a5f84;
}

/*--------------------------------  form-key-value  -----------------------------------------  */
.form-keys-values {
  width: 100%;
}
.form-keys-values .group-input-key-value {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
}
.form-keys-values .group-input-key-value .input-key {
  width: 35%;
  border-radius: 0.25rem 0 0 0.25rem;
}
.form-keys-values .group-input-key-value .input-value {
  flex-grow: 1;
  border-radius: 0;
  border-left: none;
  border-right: none;
}
.form-keys-values .group-input-key-value .input-delete {
  width: 40px;
  border-radius: 0 0.25rem 0.25rem 0;
  background-color: #FFF;
  border: solid 1px #ced4da;
}
.form-keys-values .group-input-key-value .input-delete:after {
  display: block;
  font-family: "Font Awesome 5 Free";
  font-size: 0.8rem;
  content: "\f00d";
  font-weight: 900;
}

.chart-bar {
  position: relative;
  display: flex;
  border-radius: 3px;
  padding: 2px;
  overflow: hidden;
}
.chart-bar .chart-bar-element:first-child {
  border-radius: 3px 0 0 3px;
}
.chart-bar .chart-bar-element:last-child {
  border-radius: 0 3px 3px 0;
}
.chart-bar .chart-bar-element {
  position: relative;
  height: 20px;
}

.progress {
  display: block;
  height: 20px;
  width: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.progress.progress-xs {
  height: 8px;
}
.progress.progress-sm {
  height: 16px;
}
.progress.progress-md {
  height: 20px;
}
.progress.progress-xl {
  height: 26px;
}
.progress .progress-bar {
  display: block;
  width: 10%;
  height: 100%;
  background-color: #AAA;
}
.progress .progress-bar-green {
  background-color: green;
}
.progress .progress-bar-yellow {
  background-color: yellow;
}
.progress .progress-bar-red {
  background-color: red;
}
.progress .progress-bar-blue {
  background-color: blue;
}

.canvas-chart {
  bottom: 0;
}

.timeline {
  padding: 0;
}
.timeline.timeline-xs {
  font-size: 0.9rem;
}
.timeline.timeline-xs .timeline-item-pointer {
  left: -16px;
  width: 30px;
  height: 30px;
  padding-top: 8px;
}
.timeline > li.time-label {
  margin: 0;
  padding: 0;
  border: none;
}
.timeline > li.time-label span {
  display: inline-block;
  font-family: "Dosis", "Calibri", "Arial";
  font-weight: 600;
  color: #FFF;
  background-color: #b62121;
  border-radius: 4px;
  padding: 0 0.75em;
}
.timeline li.time-label + li {
  padding-top: 2em;
}
.timeline li {
  position: relative;
  list-style: none;
  margin-left: 3em;
  padding-left: 3em;
  padding-bottom: 2em;
  border-left: solid 3px #EEE;
}
.timeline li:last-child {
  border-left: none;
}
.timeline .timeline-item-pointer {
  position: absolute;
  left: -20px;
  display: block;
  width: 40px;
  height: 40px;
  background-color: #EEE;
  border-radius: 50%;
  padding-top: 10px;
  text-align: center;
  font-weight: 900;
}
.timeline .timeline-item {
  position: relative;
  background-color: #EEE;
  border-radius: 6px;
  padding: 5px 10px;
  font-size: 0.9em;
}
.timeline .timeline-item a {
  color: #0a5f84;
}
.timeline .timeline-item .time {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 0.9em;
}
.timeline .timeline-item .timeline-header {
  font-size: 1.1em;
  font-weight: 500;
  padding: 5px 10px;
  margin: -5px -10px 0 -10px;
  border-bottom: solid 1px #FFF;
}

.list-table {
  padding: 0;
  margin: 0;
  list-style: none;
}
.list-table li {
  padding: 0.5rem 1.25rem;
  border-top: solid 1px rgba(0, 0, 0, 0.07);
}
.list-table .list-table {
  margin: 0.5rem -1.25rem -0.5rem -1.25rem;
}
.list-table .list-table li {
  padding-left: 2.25rem;
}

.list-no-border {
  border: none;
}
.list-no-border li {
  border: none;
}

.cont-bulle {
  position: absolute;
  font-family: "Dosis", "Calibri", "Arial";
  font-size: 0.9rem;
  font-weight: 600;
  background-color: #FFA;
  padding: 0 5px;
  border-radius: 2px;
  width: auto;
  border: solid 1px rgba(0, 0, 0, 0.2);
  display: block;
  opacity: 1;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
  transition: opacity 200ms ease-out;
  z-index: 100;
}
.cont-bulle .bulle {
  display: block;
}

.handle-bulle {
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  z-index: 200;
  border-top: 10px solid #FFA;
  border-right: 10px solid transparent;
}

.bulle {
  display: none;
}

.result-index {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.result-index .tn {
  display: block;
  margin-bottom: 0.5rem;
  flex-basis: 19%;
  width: 19%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
  border-radius: 0;
  background-color: #FFF;
  color: #444;
  transition: background-color 200ms ease-out, box-shadow 200ms ease-out, transform 200ms ease-out;
}
.result-index .tn:hover {
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.4);
  transform: scale(1.2);
  z-index: 10;
}
.result-index .tn .tn-picture {
  display: block;
  width: 100%;
  height: 0;
  padding-top: 65%;
  overflow: hidden;
  border-radius: 0 0 0 0;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.2);
  background-position: 50% 50%;
  background-size: cover;
}
.result-index .tn .tn-picture + .tn-content {
  border-radius: 0 0 0 0;
}
.result-index .tn .tn-content {
  border-radius: 0;
  padding: 0.75rem;
}
.result-index .tn .tn-content h3 {
  font-size: 1rem;
  font-weight: 500;
}

.result-index-list .tn {
  color: #444;
}
.result-index-list .tn .tn-content h3 {
  font-size: 1rem;
  font-weight: 500;
}

.result-categories {
  margin-bottom: 1rem;
  background-color: #F4F4F4;
  padding: 1rem;
}

h1.title-work {
  line-height: 0.9;
}

ul.list-technique {
  list-style: none;
  padding: 0;
  font-family: "Dosis", "Calibri", "Arial";
  font-weight: 500;
  font-size: 1.1em;
}

#tv-noise {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.search-cont {
  position: relative;
  margin-bottom: -40px;
  background-color: #FFF;
  padding: 0 1.5rem;
}

.search {
  padding: 1.5rem;
}

.blog-note {
  border-bottom: solid 1px #EEE;
  margin-bottom: 1.5rem;
}
.blog-note .blog-date {
  display: block;
  font-size: 0.9em;
}