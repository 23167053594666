.cont-bulle {
    .bulle{
        display: block;
    }
    position:absolute;
    font-family: $font_title;
    font-size:0.9rem;
    font-weight:600;
    background-color:#FFA;
    padding: 0 5px;
    border-radius: 2px;
    width:auto;
    border: solid 1px rgba(0,0,0,0.2);
    display:block;
    opacity:1;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,0.3);
    transition: opacity 200ms ease-out;
    z-index: 100;
}
.handle-bulle {
    display : block;
    position:absolute;
    height : 0;
    width : 0;
    z-index: 200;
    border-top : 10px solid #FFA;
    border-right : 10px solid transparent;
}
.bulle {display: none;}
