.list-table {
    padding:0;
    margin:0;
    list-style: none;
    li{
        padding: 0.5rem 1.25rem;
        border-top: solid 1px rgba(0,0,0,0.07)
    }
    .list-table {
        margin: .5rem -1.25rem -.5rem -1.25rem;
        li {
            padding-left: 2.25rem;
        }
    }
}

.list-no-border{
    border: none;
    li {border: none;}
}
