
/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Components                                                                        */
/* ///////////////////////////////////////////////////////////////////////////////////////*/

/*--------------------------------  Nav bar  -----------------------------------------    */

.nav-bar {
    position: relative;
    height: $header-height;
    background-color: $color_navbar;
    border-bottom: solid 1px rgba(0,0,0,0.05);
    > ul{
        padding:0;
        margin:0;
        li{
            list-style: none;
            display: inline-block;
            a{
                text-decoration: none;
                font-size:0.9rem;
                color: #000;
            }
        }
    }
    h1 {
        font-size:2rem;
        padding-top: 0.5rem;
        padding-left:2rem;
        font-weight: 300;
    }
}

/*--------------------------------  sous bar  -----------------------------------------    */

.sous-nav{
    position:absolute;
    width: 100%;
    right:0;
    left:0;
    top:0;
    padding:0.8rem 0 0 1.25rem;
    height: 54px;
    background-color: $color_navbar2;
    border-bottom: solid 1px rgba(0,0,0,0.05);
    ul{
        display:flex;
        padding:0;
        margin:0;
        li{
            list-style: none;
            display: inline-block;
            position:relative;
            top: 1px;
            a{
                padding: 0.6rem 1rem 0.4rem 1rem;
                display: block;
                text-decoration: none;
                color: $color_link;
                font-family: $font_title;
                font-weight: 500;
                font-size: 1rem;
                margin-right:0.5rem;
                border: solid 1px transparent;

                border-bottom: none;
            }
            &.active a{
                color:#000;
                border-color: rgba(0,0,0,0.1);
                background-color : $color_body;
            }
        }
    }

}


/*--------------------------------  nav column  -----------------------------------------  */

.nav-column{
    position:relative;
    background-color: $color_column;
    .logo{
        display:block;
        position: relative;
        width:100%;
        height: $header-height;
        background-color: $color_logo_bkg;
        font-family: $font_title;
        color:$color_logo;
        text-transform: uppercase;
        font-weight:300;
        padding:0.4rem 0.7rem;
        font-size: 1.8rem;
        span {
            color:$color_logo_2;
        }
    }
    .user-panel{
        padding:0.6rem 1rem;
        font-family: $font_title;
        position:relative;
        display: flex;
        border-bottom: solid 1px rgba(255,255,255,0.1);
        height: 55px;
        a {
            color:rgba(255,255,255,0.7);
            letter-spacing: 0.03rem;
            small {
                padding-left:1rem;
                color:$color_toolbox;
            }
        }
        .user-picto {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 33px;
            height: 33px;
            margin-right: 0.7rem;
            color: $color_logo_2;
            border: solid 2px $color_logo_2;
            border-radius: 50%;
        }
    }
    .sidebar{
        nav {
            font-family: $font_title;
            font-weight:500;
            font-size:0.9rem;
            color:rgba(255,255,255,0.7);
            > ul > li.nav-item > a {
                padding:0.6rem 1rem;
            }
            > ul > li.nav-item > a > i, > ul > li.nav-item > a > p > i {top:0.6rem;}
            > ul > li.nav-item > ul.nav-treeview > li > a {padding-left: 1.5rem;}
            > ul > li.nav-item > ul.nav-treeview > li > a > i.fa,
            > ul > li.nav-item > ul.nav-treeview > li > a > i.fas
            {left: 1.5rem;}
            ul{
                padding:0;
                margin:0;
                li {
                    position:relative;
                    list-style: none;
                    &.nav-header{
                        text-transform: uppercase;
                        color: rgba(255,255,255,0.4);
                        padding: 1.2rem 1rem;
                        letter-spacing: 0.1em;
                    }
                }
                p {
                    display:inline-block;
                    margin:0;
                    padding-left:1.6rem;
                }
                a{
                    display:block;
                    padding:0.3rem 1rem;
                    color:inherit;
                }

                i{
                    position:absolute;
                    left:1rem;
                    top:0.3rem;
                    font-size:1rem;
                    &.right{
                        left: auto;
                        right:0.5rem;
                        transition: transform ease-out 300ms;
                    }
                }
                .nav-treeview{
                    overflow : hidden;
                    height:0;
                    transition:height ease-out 200ms;
                    background-color: rgba(0,0,0,0.1);
                }
                .menu-open {
                    i.right{
                        transform: rotate(-90deg);
                    }
                }
                .active{
                    color:rgba(255,255,255,0.8);
                    > a  i{
                        color: $color_toolbox;
                    }
                    > a {
                        background-color: rgba(0,0,0,0.2);
                    }
                }
                .sel{
                    > a  i{
                        color: $color_toolbox;
                    }
                }
            }
        }
    }
}


/*--------------------------------  Breadcrumb  -----------------------------------------  */

.breadcrumb-cont{
    display:flex;
    justify-content: flex-end;
    margin-top: -10px;
}
.sous-nav + .breadcrumb-cont{margin-top: 40px;}
.breadcrumb{
    padding-top:0.5rem;
    font-family: $font_title;
    font-size:1rem;
    font-weight: 500;
    margin-bottom:.6rem;
    li {
        display:inline-block;
        list-style: none;
        a {
            color: $color_toolbox_dark;
        }
        &:after{
            display: inline-block;
            padding: 0 10px;
            content: '/';
        }
        &:last-child:after{
            display:none;
        }
    }
}

/*--------------------------------  pagination  -----------------------------------------  */

.pagination {
    border: solid 1px #CCC;
    margin:0;
    padding:0;
    display: inline-flex;
    width: auto;
    border-radius: 4px;
    li {
        list-style: none;
        border-right:solid 1px #CCC;
        a, span {
            display: block;
            padding:0.5rem 0.8rem;
            color: #555;
        }
        &.active span {
            background-color: #CCC;
            color: #FFF;
        }
        &:last-child{ border-right:0; }
    }
}

/*--------------------------------  list nav  -----------------------------------------  */

.list-nav {
    padding:0;
    margin:0;
    li{
        list-style: none;
        input{
            display:none;
        }
        label{
            display:block;
            padding:0.5rem 0.85rem 0.5rem 2rem;
            border-bottom: solid 1px rgba(0,0,0,0.1);
            .badge {font-size: 0.7rem;}
            &:before {
                top: 0.5rem;
            }
        }
    }
}

/*--------------------------------  user list  -----------------------------------------  */
.user-list{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
    padding:0;
    li{
        list-style: none;
        text-align: center;
        margin: 0.5rem;
    }
    .user-list-item {
        font-size: 0.9rem;
        width: 100px;
        color: $color_link;
        display:block;
        .user-list-img {
            display:inline-block;
            width: 100px;
            padding-top:100px;
            height:0;
            background-color: rgba(0,0,0,0.2);
            background-size: cover;
            border-radius: 50%;
        }
        .user-list-name{
            display: block;
            font-weight: 600;
        }
        user-list-date{
            display: block;
        }
    }
}

/*--------------------------------  Alert  -----------------------------------------  */

.alert-cont{
    position:fixed;
    z-index: 1000;
    top:0;
    right:0;
    width:33%;
    height:0;
    transition: height ease-out 300ms;

    .alert{
        position:absolute;
        bottom:0;
        width:100%;
        padding:10px;
        border-radius: 0 0 0 8px;
        background-color: lighten($color_toolbox,20);
        color: #FFF;
        font-size:0.8rem;
        box-shadow: 0 0 10px  rgba(0,0,0,0.4);
        h5 {font-size:1rem;}
        button {
            color:#FFF;
            display:block;
            position:absolute;
            top: 10px;
            right: 10px;
            border: 0;
            background-color: transparent;
        }

        &.alert-danger {border-color: $color_danger; background-color: $color_danger;}
        &.alert-info {border-color: $color_info; background-color: $color_info;}
        &.alert-success {border-color: $color_success; background-color: $color_success;}
        &.alert-warning {border-color: $color_warning; background-color: $color_warning;}
        &.alert-link {border-color: $color_link; background-color: $color_link;}
        &.alert-primary {border-color: $color_primary; background-color: $color_primary;}
        &.alert-default {border-color: $color_default; background-color: $color_default;}
    }
}

/*--------------------------------  DialogBox  -----------------------------------------  */

.dialog-box-cloud{
    display: flex;
    position: fixed;
    overflow: hidden;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(0,0,0,0.2);
    top: 0;
    left : 0;
    right: 0;
    bottom: 0;
 }
.dialog-box{
    position: relative;
    display: block;
    padding: 20px;
    background-color: #FFF;
    width: 450px;
    height: 150px;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
    .dialog-box-message{
        font-size: 1rem;
        padding-left: 40px;
    }
    .dialog-box-footer{
        position:absolute;
        right: 0;
        left: 0;
        bottom:0;
        padding: 20px;
        text-align: right;
    }
    .btn {
        color: #FFF !important;
    }
    .btn + .btn {margin-left: 20px;}
    &:after{
        position:absolute;
        display: block;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: '\f06a';
        color: $color_warning;
        font-size: 2em;
        top: 0;
        left: 10px;
    }
 }

