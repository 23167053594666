.timeline{
    &.timeline-xs {
        font-size: 0.9rem;
        .timeline-item-pointer{
            left:-16px;
            width:30px;
            height:30px;
            padding-top: 8px;
        }
    }
    padding:0;
    > li.time-label{
        margin:0;
        padding:0;
        border:none;
        span{
            display: inline-block;
            font-family: $font_title;
            font-weight: 600;
            color: #FFF;
            background-color: #b62121;
            border-radius: 4px;
            padding:0 0.75em;
        }
    }
    li.time-label + li {padding-top:2em;}
    li {
        position:relative;
        list-style: none;
        margin-left:3em;
        padding-left:3em;
        padding-bottom:2em;
        border-left: solid 3px #EEE;
    }
    li:last-child{border-left: none;}

    .timeline-item-pointer{
         position:absolute;
         left:-20px;
        display:block;
        width:40px;
        height:40px;
        background-color: #EEE;
        border-radius: 50%;
        padding-top: 10px;
        text-align: center;
        font-weight:900;
     }
    .timeline-item{
        position:relative;
        background-color: #EEE;
        border-radius: 6px;
        padding: 5px 10px;
        font-size:0.9em;
        a {color: $color_link;}
        .time{
            position:absolute;
            right:10px;
            top:5px;
            font-size:0.9em;
        }
        .timeline-header{
            font-size:1.1em;
            font-weight: 500;

            padding:5px 10px;
            margin: -5px -10px 0 -10px;
            border-bottom: solid 1px #FFF;
        }
    }
}
