
$color_border_table : #dee2e6;
.table {
    width:100%;
    font-size:0.9rem;
    &.table-sm {font-size:0.8rem;}
    thead th {
        vertical-align: bottom;
        border-bottom: 2px solid $color_border_table;
    }
    td, th {
        padding: .75rem;
        vertical-align: top;
        border-top: 1px solid $color_border_table;
    }

    &.table-striped{
        tr:nth-child(odd) td{
            background-color: rgba(0,0,0,0.02);
        }
    }

    &.no-border {
        &,
        td,
        th {
            border: 0;
        }
    }

    &.text-center {
        &,
        td,
        th {
            text-align: center;
        }
    }

    &.table-valign-middle {
        thead > tr > th,
        thead > tr > td,
        tbody > tr > th,
        tbody > tr > td {
            vertical-align: middle;
        }
    }

    .card-body.p-0 & {
        thead > tr > th,
        thead > tr > td,
        tbody > tr > th,
        tbody > tr > td {
            &:first-of-type {
                padding-left: 1.25rem;
            }

            &:last-of-type {
                padding-right: 1.25rem;
            }
        }
    }

    &.table-condensed {
        font-size:0.7rem;
        td, th {
            padding: .25rem;
        }
    }

    &.table-sortable{
        th.sortable{
            position:relative;
            padding-left:2rem;
            cursor: pointer;
            &:after {
                display:block;
                position:absolute;
                left:0.75rem;
                top:0.75rem;
                color: $color_link;
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: '\f0dc';
                opacity: 0.5;
            }
            &.sortable-active:after{
                content: '\f160';
                opacity: 1;
            }
            &.sortable-active.sortable-desc:after{
                content: '\f884';
            }
            &:first-child{

            }
        }
    }

    &.table-border {
        border: solid 2px #CCC;
    }
    &.table-tiny-border {
        border: solid 1px #CCC;
    }
    &.table-shadow {
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.13), 0 1px 3px rgba(0, 0, 0, 0.2);
    }

}
