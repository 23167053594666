.float-right{
    float:right;
}

a {color: $color_link;}

.font-text {font-family: $font-text;}
.font-title {font-family: $font-title;}

.d-none{display: none !important;}
.d-out{position: absolute; top : -9999px; left : -9999px;}
.d-block{display: block;}
.d-inline{display: inline;}
.d-inline-block{display: inline-block;}
.d-flex{display: flex;}

.h-0 {position:absolute; top: -9999px;}
.p-0{padding:0 !important;}
.m-0{margin:0 !important;}

.text-left{ text-align: left;}
.text-center{ text-align: center;}
.text-right{ text-align: right;}

.break-word {overflow-wrap: break-word !important;}

.list-unstyled{
    padding:0;
    margin:0;
    list-style: none;
}

dl.key-value {
    display: flex;
    flex-wrap: wrap;
    font-family: $font_title;
    border-top : solid 2px rgba(0,0,0,0.2);
    border-bottom : solid 2px rgba(0,0,0,0.2);
    dt,dd {
        padding: 0.2em 0 0.1em 0;
        border-bottom : solid 1px rgba(0,0,0,0.1);
        margin-bottom: 0;
        flex: 0 0 50%;
    }
    dt{
        font-weight: 600;
        &:last-of-type{border-bottom-width : 0;}
    }
    dd{
        font-weight: 500;
        text-align:right;
        &:last-child{border-bottom-width : 0;}
    }
}

.font-size-09 {
    font-size: 0.9em;
}

.clear {clear: both;}

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*     Couleur et deco                                                                    */
/* ///////////////////////////////////////////////////////////////////////////////////////*/

.bg-default{ background-color: $color_default !important; color: #FFF; }
.bg-primary{ background-color: $color_primary !important; color: #FFF;  }
.bg-success{ background-color: $color_success !important; color: #FFF;  }
.bg-info{ background-color: $color_info !important; color: #FFF;  }
.bg-warning{ background-color: $color_warning !important; color: #FFF;  }
.bg-danger{ background-color: $color_danger !important; color: #FFF;  }
.bg-horizon{ background-color: $color_horizon !important; color: #FFF;  }
.bg-sable{ background-color: $color_sable !important; color: #FFF;  }

.bg-horizon-light{ background-color: lighten($color_horizon, 25%) !important; color: #FFF;  }

.soft-gradient {background-image: linear-gradient(rgba(255,255,255,0.2), rgba(0,0,0,0.2))}

.stripped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
    background-size: 16px 16px;
}
.soft-gradient.stripped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent), linear-gradient(rgba(255,255,255,0.2), rgba(0,0,0,0.2));
    background-size: 16px 16px, cover;
}

.ecosse {
    background-image:
        linear-gradient(45deg,
            rgba(0, 0, 0, 0.1) 2%, transparent 2%, transparent 20%, rgba(255, 255, 255, 0.1) 15%, rgba(255, 255, 255, 0.1) 35%, transparent 35%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 52%, transparent 52%, transparent 70%, rgba(255, 255, 255, 0.1) 65%, rgba(255, 255, 255, 0.1) 85%, transparent 85%),
        linear-gradient(-45deg,
            rgba(0, 0, 0, 0.1) 2%, transparent 2%, transparent 20%, rgba(255, 255, 255, 0.1) 15%, rgba(255, 255, 255, 0.1) 35%, transparent 35%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 52%, transparent 52%, transparent 70%, rgba(255, 255, 255, 0.1) 65%, rgba(255, 255, 255, 0.1) 85%, transparent 85%);
    background-size: 45px 45px, 45px 45px;
}
.soft-gradient.ecosse {
    background-image:
        linear-gradient(45deg,
            rgba(0, 0, 0, 0.1) 2%, transparent 2%, transparent 20%, rgba(255, 255, 255, 0.1) 15%, rgba(255, 255, 255, 0.1) 35%, transparent 35%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 52%, transparent 52%, transparent 70%, rgba(255, 255, 255, 0.1) 65%, rgba(255, 255, 255, 0.1) 85%, transparent 85%),
        linear-gradient(-45deg,
            rgba(0, 0, 0, 0.1) 2%, transparent 2%, transparent 20%, rgba(255, 255, 255, 0.1) 15%, rgba(255, 255, 255, 0.1) 35%, transparent 35%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 52%, transparent 52%, transparent 70%, rgba(255, 255, 255, 0.1) 65%, rgba(255, 255, 255, 0.1) 85%, transparent 85%),
        linear-gradient(rgba(255,255,255,0.2), rgba(0,0,0,0.2));
    background-size: 45px 45px, 45px 45px, cover;
}

.shadow-light {
    box-shadow: 0 3px 6px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23)!important;
}
.shadow-medium {
    box-shadow: 0 3px 6px rgba(0,0,0,.3),0 3px 6px rgba(0,0,0,.5)!important;
}
.shadow-strong {
    box-shadow: 0 3px 6px rgba(0,0,0,.5),0 3px 6px rgba(0,0,0,.8)!important;
}



