@use "sass:math";
html {
    line-height: 1.15;
    min-height: 100%;
}
body{
    font-family: $font_text, sans-serif ;
    background-color: $color_body;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    min-height: 100%;
}
body.fixed {
    .header {
        top: 0;
        width: 100%;
        height: 81px;
        position: fixed;
    }
}

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Grid                                                                              */
/* ///////////////////////////////////////////////////////////////////////////////////////*/

.wrapper {
    position: relative;
    padding-top: $header-height;
    height: 100%;
}

.header{
    z-index: 10;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: $color_logo_bkg;
    background-image: url('../img/ComfyUI_01187_.png');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: $header-height;
    .main-header{
        font-family: $font_title;
        text-transform: uppercase;
        .header-logo {
            z-index: 2;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: 40px;
            overflow: hidden;
            margin: 0 ;
            padding: 0 1rem 0 3rem;
            background-color: #FFF;
            .logo{
                font-size: 1.4em;
                font-weight: 300;
                color: rgba(0,0,0,1);
            }
        }
    }
}

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      navigation                                                                        */
/* ///////////////////////////////////////////////////////////////////////////////////////*/

ul.main-navigation{
    position: absolute;
    bottom: 0;
    list-style: none;
    padding:  0;
    margin: 0 0 0 3rem;
    display: flex;
    border-style: solid;
    border-color: #ccc;
    border-width: 0 0 1px 0;
    li {
        a {
            display: block;
            padding: 0.5rem 1rem;
            border-style: solid;
            border-color: #ccc;
            border-width: 0 1px 0 0;
            color : #000;
            font-size: 0.9em;
            text-transform: uppercase;
            background-color: #DFDFDF;
            &.nav-btn-search {
                background-color: #fb8a00;
                border-color: #fb8a00;
                color: #FFF;
            }
            &.sel {
                background-color: #FFF;
            }
        }
        &:first-child a {border-width: 0 1px 0 1px;}
    }
    .search-bar-cont {
        position: relative;
        overflow: hidden;
        width: 105px;
        transition: width 200ms ease-out;
    }
    .search-bar {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        padding: 0.3rem 0.6rem;
        border-style: solid;
        border-width: 0 1px 0 0;
        font-size: 0.9em;
        text-transform: uppercase;
        background-color: #fb8a00;
        border-color: #fb8a00;
        color: #FFF;
        input[type=text]{
            border: 2px solid #FFF;
        }
        .search-bar-btn {
            padding: 0 1rem;
            &:focus {
                outline-color: #fb8a00;
                outline-style: none;
                border-color: #fb8a00;
            }
        }
    }
}

ul.sous-navigation {
    position: relative;
    list-style: none;
    padding:  0;
    margin: -1rem 0 1rem 0;
    display: flex;
    border-style: solid;
    border-color: #CCC;
    border-width: 0 0 1px 0;
    li a {
        position: relative;
        display: block;
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-style: solid;
        border-color: #FFF;
        border-width: 1px 1px 0 1px;
        border-radius: 4px 4px 0 0;
        color : #000;
        font-size: 0.8em;
        text-transform: uppercase;
        &.sel {
            top: 1px;
            border-color: #ccc;
            font-weight: 900;
            background-color: #FFF;
        }
    }
}
ul.order-filter{
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0 0 1rem 0;
    display: flex;
    li a {
        position: relative;
        display: block;
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
        color : #888;
        font-size: 0.9em;
        cursor: pointer;
        &.sortable-active {
            color: #000;
        }
        &:after {
            display:block;
            position:absolute;
            left:0;
            top:0.5rem;
            color: $color_link;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: '\f0dc';
            opacity: 0.5;
        }
        &.sortable-active:after{
            content: '\f160';
            opacity: 1;
        }
        &.sortable-active.sortable-desc:after{
            content: '\f884';
        }
        &:first-child{

        }

    }
}


/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Fiche                                                                          */
/* ///////////////////////////////////////////////////////////////////////////////////////*/

.fiche {


    > .fiche-content {

    }
    > .fiche-info {

    }
    > .fiche-cartouche {

    }
}


/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Categorie                                                                         */
/* ///////////////////////////////////////////////////////////////////////////////////////*/

    .category-handle {
        position: relative;
        cursor: pointer;
        &:before {
            display:block;
            position:absolute;
            left:-20px;
            top:0;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: '\f0da';
            opacity: 0.5;
        }
        &.open:before { content: '\f0d7';}
    }
    .category-list {
        height: 0;
        visibility: hidden;
        &.open {height: auto; visibility: visible;}
    }

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Layout                                                                            */
/* ///////////////////////////////////////////////////////////////////////////////////////*/
.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-gap: 20px;
    grid-auto-rows: minmax(100px, auto);
    > .content{
        padding: 2rem 3rem;
    }
    > .categories{
        background-color: #DFDFDF;
        padding: 2rem 3rem;
        height: 100%;
        .category-sel {
            position: relative;
            font-weight: 900;
            &:before {
                display: block;
                position: absolute;
                content: '\f00c';
                font-size: 1rem;
                color: $color_link;
                font-weight: 900;
                top: 0;
                left: -20px;
                font-family: "Font Awesome 6 Free";
            }
        }
    }
}

$gutter-width:7.5px;
$gutter-width-double:15px;

.row{
    display: flex;
    flex-wrap: wrap;
    margin-right: -$gutter-width;
    margin-left: -$gutter-width;
    &.row-gutter-double {
        > div:first-child {
            padding-right: $gutter-width-double;
            padding-left: $gutter-width;
        }
        > div:last-child {
            padding-right: $gutter-width;
            padding-left: $gutter-width-double;
        }
        > div {
            padding-right: $gutter-width-double;
            padding-left: $gutter-width-double;
        }
        &.row-gutter-line {
            > div:last-child {
                border-right-width : 0;
            }
            > div {
                border-right: 1px solid rgba(0,0,0,0.1);
            }
        }
    }
    > div{
        position: relative;
        width: 100%;
        padding-right: 7.5px;
        padding-left: 7.5px;
    }

    $flex-col: 12;

    @for $i from 1 through $flex-col {
        .col-#{$i}, .col-md-#{$i}, .col-sm-#{$i}, .col-xs-#{$i} {
            flex: 0 0 math.div(100% , math.div($flex-col , $i));
            max-width: math.div(100% , math.div($flex-col , $i));
        }
    }
}

.multi-column{
    column-count: 3;
}

/*/////////////////////////////////////////////////////////*/
/* liste navigation */

.list-navigation{
    display: flex;
    justify-content: space-between;
}
.list-quantity {
    .form-control {
        height: calc(2.7rem + 2px);
    }
}
.list-navigation-display {
    .btn-display {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #CCC;
        width: 42px;
        height: 42px;
        border: solid 1px #CCC;
        background-color: #FFF;
        border-radius: 4px;
        &.actif {
            background-color: #ccc;
            color: #FFF;
        }
    }
}
.list-pagination-btns {
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-end;
}

.table-btn-order{
    position: relative;
    color: #555;
    &.sel  {
        color: #000;
    }
    &.desc::before {
        display: block;
        position: absolute;
        content: '\f884';
        font-size: 1rem;
        color: #555;
        font-weight: 900;
        top: 0;
        right: -20px;
        font-family: "Font Awesome 6 Free";
    }
    &.asc::before {
        display: block;
        position: absolute;
        content: '\f160';
        font-size: 1rem;
        color: #555;
        font-weight: 900;
        top: 0;
        right: -20px;
        font-family: "Font Awesome 6 Free";
    }
}



