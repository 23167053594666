/*--------------------------------  Button  -----------------------------------------  */

.btn, .cartouche {
    &.disabled,
    &:disabled {
        cursor: not-allowed;
    }
    display: inline-block;
    margin-bottom: 0;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: transparent;
    border:0;
    color:inherit;

    &.btn-link{
        background-color: transparent;
        padding: 0;
        border:0;
        color: $color_link;
        &:hover{
            color: $color_text;
        }
    }

    &.btn-default {background-color: $color_default; color:#111;}
    &.btn-primary {background-color: $color_primary; color:#FFF;}
    &.btn-success {background-color: $color_success; color:#FFF;}
    &.btn-info {background-color: $color_info; color:#FFF;}
    &.btn-warning {background-color: $color_warning; color:#111;}
    &.btn-danger {background-color: $color_danger;color:#FFF;}

    &.btn-outline{padding: 5px 11px; background-color: transparent !important; border:solid 1px $color_default; color:#333;}
    &.btn-outline.btn-default {border-color: $color_default; color:#333;}
    &.btn-outline.btn-primary {border-color: $color_primary; color:$color_primary;}
    &.btn-outline.btn-success {border-color: $color_success; color:$color_success;}
    &.btn-outline.btn-info {border-color: $color_info; color:$color_info;}
    &.btn-outline.btn-warning {border-color: $color_warning; color:$color_warning;}
    &.btn-outline.btn-danger {border-color: $color_danger; color:$color_danger;}

    &.btn-lg{padding: 9px 18px; font-size:1.1rem;}
    &.btn-sm{padding: 2px 12px; font-size:0.8rem;}
    &.btn-xs{padding: 2px 9px; font-size:0.7rem;}
    &.btn-outline.btn-lg{padding: 8px 17px;}
    &.btn-outline.btn-sm{padding: 1px 11px;}
    &.btn-outline.btn-xs{padding: 1px 8px;}
    &.btn-flat{border-radius: 0;}
    &.btn-disabled{ opacity:0.5;}
}
.btn + .btn, .btn + form {margin-left: 5px;}
.cartouche {cursor: default;}
/*--------------------------------------------------------------------------------------------------------------------------*/


.form-group, .form-group-inline{
    margin-bottom: 1rem;
    label{
        display: inline-block;
        margin-bottom: .3rem;
        font-size:0.9rem;
        &:not(.check-label){
            font-weight: 600;
        }
    }
}

.form-group-inline{
    display: flex;
    flex-wrap: wrap;
    label{
        flex-basis: 33%;
        margin-bottom: 0.75rem;
        padding: 0.5rem 0.75rem 0 0;
    }
    .form-control{
        flex-basis: 66%;
        width: auto;
        margin-bottom: 0.75rem;
    }
}

.input-group {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
    margin-bottom: 1rem;
    .form-control {
        position: relative;
        flex: 1 1 0%;
        min-width: 0;
        margin-bottom: 0;
    }
    & > .form-control:not(:last-child){
       border-top-right-radius: 0;
       border-bottom-right-radius: 0;
    }
    & > .form-control:not(:first-child){
       border-top-left-radius: 0;
       border-bottom-left-radius: 0;
    }
    .input-group-append {
        margin-left: -1px;
        .btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    .input-group-prepend {
        margin-right: -1px;
        .btn {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .input-group-append, .input-group-prepend {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.input-group-sm {
        .form-control{
            height: calc(1.75rem + 2px);
            padding: .375rem .75rem;
            font-size: 0.9rem;
        }
        .btn{
            padding: .27rem .75rem;
            font-size: 0.9rem;
        }
    }
    &.input-group-xs {
        .form-control{
            height: calc(1.3rem + 2px);
            padding: .2rem .75rem;
            font-size: 0.8rem;
        }
        .btn{
            padding: 0 .5rem;
            font-size: 0.7rem;
            line-height:1;
        }
    }
}

.form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $color_text;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s;
    &:focus{
        outline: 0;
        border-color: $color_link;
    }
    &::placeholder {
        color: rgba(0,0,0,0.4);
    }
    option{
        font-size: 0.9rem;
        padding: 0.3rem 0.1rem;
    }
    &[disabled] {
        opacity: 0.4;
        background-color: rgba(0,0,0,0.1);
    }
    &.form-control-sm{
        height: calc(1.75rem + 2px);
        padding: .375rem .75rem;
        font-size: 0.9rem;
    }
    &.form-control-xs{
        height: calc(1.3rem + 2px);
        padding: .2rem .75rem;
        font-size: 0.8rem;
    }
    &.form-control-inline {
        display: inline-block;
        width: auto;
    }
}
.has-error{
    .form-control {
        border-color: $color_danger;
    }
    .label-error {
        display:block;
        color: $color_danger;
        font-size: 0.9rem;
        margin: 0;
      }
    .input-group ~.label-error {
        margin-top: -1rem;
    }
}


textarea.form-control { height: auto;}
select.form-control[multiple], select.form-control[size] {
    height: auto;
}

.form-check, .form-check-inline{
    position: relative;
    display: block;
    input:disabled~label, input[disabled]~label {
        color: #6c757d;
    }
    label{
        font-weight:400 !important;
    }
    input[type=checkbox],input[type=radio]{
        position: absolute;
        margin-top: .3rem;
        margin-left: -1.25rem;
    }
}
.form-check-inline{
    display:inline-block;
    margin-right: 1rem;
}

textarea.form-control {
    height: auto;
}

/*------------------------------------------------*/

label.checkbox{
    &.checkbox-opacity{opacity: 0.5;}
    &.checkbox-crossed{text-decoration: line-through;}
    position:relative;
    display:inline-block;
    margin:0;
    font-size: 0.9rem;
    cursor: pointer;
    padding:0 0 0 2rem;
    .message-on{ display: none;}
    .message-off{ display: inline;}
    &:before {
        display:block;
        content: "\f0c8";
        position:absolute;
        color: #BBB;
        font-family: 'Font Awesome 5 Free';
        font-weight: 500;
        top: 0;
        left: 0.7rem;
    }
}
input:checked+label.checkbox {
    &.checkbox-opacity{opacity: 1;}
    &.checkbox-crossed{text-decoration: none;}
    .message-on{ display: inline;}
    .message-off{ display: none;}
    &:before{
        content: "\f14a";
        color: $color_primary;
        font-weight: 900;
    }
}
label.radio{
    &.radio-opacity{opacity: 0.5;}
    &.radio-crossed{text-decoration: line-through;}
    position:relative;
    display:inline-block;
    margin:0;
    font-size: 0.9rem;
    cursor: pointer;
    padding:0 0 0 2rem;
    .message-on{ display: none;}
    .message-off{ display: inline;}
    &:before {
        display:block;
        content: "\f111";
        position:absolute;
        color: #BBB;
        font-family: 'Font Awesome 5 Free';
        font-weight: 500;
        top: 0;
        left: 0.7rem;
    }
}
input:checked+label.radio {
    &.radio-opacity{opacity: 1;}
    &.radio-crossed{text-decoration: none;}
    .message-on{ display: inline;}
    .message-off{ display: none;}
    &:before{
        content: "\f111";
        color: $color_primary;
        font-weight: 900;
    }
}

.input-color{
    display: inline-block;
    width: 100%;
    border-radius: 4px;
    input[type=color]{
        position : relative;
        padding:0;
        border:0;
        opacity:0;
    }
}

/*----------------------------------------------------------------------*/
/*------------------      Form Xtras          --------------------------*/
/*----------------------------------------------------------------------*/

/* select xtras */
.input-group .select-x-container {
    display:inline-block;
    .select-x-zone{border-top-right-radius: 0;border-bottom-right-radius: 0;}
}
.select-x-container {
    position:relative;
    display: block;
    width: 100%;
    .container-option{
        display:none;
        position:absolute;
        left:0;
        top:38px;
        width:100%;
        .zone-option{
            padding:0.5rem;
            display:block;
            width:100%;
            background-color: #FFF;
            border-radius: 0 0 4px 4px;
            border: solid 1px rgba(0,0,0,0.2);
            border-top: none;
            box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
            .search {
            width: 100%;
            }
            .list-options{
                padding:0;
                font-size: 0.9rem;
                overflow-y: auto;
                max-height:150px;
                margin: 5px 0 0 0;
                li{
                    list-style: none;
                    padding: 0.2rem 0.5rem;
                    border-top: solid 1px rgba(0,0,0,0.1);
                    &:first-child{border-top: none;}
                    cursor: pointer;
                    &:hover{
                        background-color: rgba(0,0,0,0.05);
                    }
                }
            }
        }

        .create-new-option{
            display: none;
            &.show {display: block;}
        }
    }
    .select-x-zone {
        position:relative;
        display: flex;
        cursor: pointer;
        padding:0.375rem 0.75rem;
        border-radius: 4px;
        border: solid 1px rgba(0,0,0,0.2);
        background-color: #fff;
        .zone-label{
            color: #666;
            white-space: nowrap;
            overflow: hidden;
            flex-grow: 1
        }
        .zone-arrow{
            border-left: solid 1px  rgba(0,0,0,0.2);
            width:30px;
            margin:-0.375rem -0.75rem -0.375rem 0;
            &:after{
                padding: 6px 9px;
                display: block;
                font-family: "Font Awesome 5 Free";
                content: "\f107";
                font-weight: 900;
            }
        }
        .zone-reset{
            display:none;
            border-left: solid 1px  rgba(0,0,0,0.2);
            margin:-0.375rem 0 -0.375rem 0;
            width:30px;
            &:after{
                padding: 9px 9px;
                display: block;
                font-family: "Font Awesome 5 Free";
                font-size:0.8rem;
                content: "\f00d";
                font-weight: 900;
            }
        }
    }
    &.open{
        .zone-arrow:after{content: '\f106';}
        .container-option{display:block;}
        .select-x-zone { border-radius: 4px 4px 0 0 ;}
    }
    &.selected .zone-label{
        color: #000;
    }
    &.selected .zone-reset{
        display:block;
    }
}

.select-x-multiple{
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
    width: 100%;
    background-color: rgba(255,255,255, 0.7);
    border-radius: 4px 4px 0 0;
    border: solid 1px rgba(0,0,0,0.2);
    border-bottom: none;
    .select-x-multiple-item{
        overflow: hidden;
        position: relative;
        margin-right: 3px;
        margin-bottom: 3px;
        padding-right: 20px;
        .select-x-multiple-item-remove{
            position: absolute;
            display: flex;
            width: 16px;
            height: 100%;
            top: 0;
            right: 0;
            cursor: pointer;
            justify-content: center;
            align-items: center;
            &:before{
                display: block;
                font-family: "Font Awesome 6 Free";
                content: '\f057';
            }
        }
    }
}

.has-error .select-x-zone {border-color: $color_danger;}


/*----------------------------------------------------------------------*/
/*------------------      Form order field    --------------------------*/
/*----------------------------------------------------------------------*/

.order-field-container {
    list-style: none;
    padding: 0;
    &:empty{margin: 0;}
    li{
        position: relative;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        margin-bottom: 0.5rem;
        border: solid 1px rgba(0,0,0,0.2);
        border-radius: 4px;
        background-color: #FFFFFFAA;
        &.new {
            border-color: #0056b3;
            color: #0056b3;
            &:before {
                position: absolute;
                top: 0;
                right:25px;
                display: block;
                background: #0056b3;
                color: #FFF;
                width: 45px;
                height: 10px;
                font-size: 0.6em;
                line-height: 0.8;
                content: 'nouveau';
                text-align: center;
            }
        }
        .order-field-middle{
            flex-grow: 1;
            padding: 2px 5px;
        }
        .order-field-action {
            flex-basis: 25px;
            position: relative;
            width: 25px;
            border-left: solid 1px rgba(0, 0, 0, 0.2);
            .order-field-delete{
                position: absolute;
                top: 0;
                left:0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25px;
                height: 100%;
                color: #dc0000;
                &:hover {
                    background-color: #dc0000;
                    color: #FFF;
                }
                &:before{
                    top: 0;
                    left:0;
                    display: block;
                    width: 25px;
                    height: auto;
                    font-family: "Font Awesome 6 Free";
                    font-weight: bold;
                    font-size: 0.7em;
                    content: '\f00d';
                    text-align: center;
                }
            }
        }
        .order-field-up-down {
            flex-basis: 25px;
            position: relative;
            width: 25px;
            border-right: solid 1px rgba(0,0,0,0.2);
            .order-field-btn-up, .order-field-btn-down{
                position: absolute;
                top: 0;
                left:0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25px;
                height: 50%;
                &:hover {
                    background-color: #0056b3;
                    color: #FFF;
                }
                &:before{
                    top: 0;
                    left:0;
                    display: block;
                    width: 25px;
                    height: auto;
                    font-family: "Font Awesome 6 Free";
                    font-weight: bold;
                    font-size: 0.7em;
                    content: '\f077';
                    text-align: center;
                }
            }
            .order-field-btn-down{
                border-top: solid 1px rgba(0,0,0,0.2);
                &:before{
                    content: '\f078';
                }
            }
            .order-field-btn-up + .order-field-btn-down {
                top: 50%;
                height: 50%;
            }
        }
        &.first .order-field-btn-down{height:100%; border-top: none;}
        &.last .order-field-btn-up{height:100%;}
    }
    span.item-option {
        position: relative;
        margin : 0 -5px -2px -5px;
        display: block;
        border-top: solid 1px rgba(0, 0, 0, 0.2);
        background-color: rgba(0, 0, 0, 0.03);
        padding: 5px;
        &:empty{display: none;}
        select option:checked { background-color: rgba(0,0,0,0.05); }
        select.empty {
            background-color: rgba(0, 0, 0, 0.02);
            color: #888;
            option { color: #000;}
        }
    }
}

/*----------------------------------------------------------------------*/
/*------------------      Form Geo            --------------------------*/
/*----------------------------------------------------------------------*/
.form-autocomplete {
    position:absolute;
    width:100%;
    ul {
        font-size: 0.9rem;
        padding:0;
        margin:0;
        background-color:#FFF;
        border: solid 1px #DDD;
        border-top:none;
        border-radius: 0 0 6px 6px;
        li{
            list-style: none;
            padding:3px 0.75rem;
            cursor: pointer;
            &:hover {background-color: #EEE;}
            border-bottom: solid 1px #DDD;
            em{
                float:right;
                color:#777;
            }
            &:last-child{border-radius: 0 0 6px 6px;}
        }
    }
}
input:focus + .form-autocomplete ul {border-color: $color_link;}


/*--------------------------------  form-key-value  -----------------------------------------  */

.form-keys-values {
    width:100%;
    .group-input-key-value{
        width:100%;
        display:flex;
        margin-bottom: 1rem;
        .input-key {
            width:35%;
            border-radius: 0.25rem 0 0 0.25rem;
        }
        .input-value {
            flex-grow: 1;
            border-radius: 0;
            border-left: none;
            border-right: none;
        }
        .input-delete{
            width:40px;
            border-radius: 0 0.25rem 0.25rem 0;
            background-color: #FFF;
            border: solid 1px #ced4da;
            &:after{
                display: block;
                font-family: "Font Awesome 5 Free";
                font-size: 0.8rem;
                content: "";
                font-weight: 900;
            }

        }
    }

}



